const getIdFromLabel = ({ options, label }) => {
  if (!options) {
    return null;
  }

  const option = options.find((option) => option.label === label);
  return option?.id;
};

export default getIdFromLabel;
