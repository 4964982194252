import PropTypes from "prop-types";

import { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu as FilterMenu,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { FilterList as FilterListIcon } from "@mui/icons-material";

const filterOptions = [
  {
    id: 1,
    label: "Comments",
    type: "hasComment",
    isChecked: true,
  },
  {
    id: 2,
    label: "Events",
    type: "hasEvent",
    isChecked: true,
  },
  {
    id: 4,
    label: "Five Points",
    type: "hasWellbeingPoint",
    isChecked: true,
  },
];

const initialFilters = {
  hasComment: false,
  hasEvent: false,
  hasWellbeingPoint: false,
};

const Filter = ({ handleFilter }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const handleOnChange = (e, option) => {
    // Add the selected option to the filter object
    setFilters({
      ...filters,
      [option.type]: e.target.checked,
    });
  };

  const handleApplyFilters = () => {
    handleFilter(filters);
    setIsFilterMenuOpen(false);
  };

  const handleOnResetFilter = () => {
    setFilters(initialFilters);
    setIsFilterMenuOpen(false);
    handleFilter(initialFilters);
  };

  return (
    <>
      <IconButton
        aria-label="Filters"
        id="filter-button"
        color={"primary"}
        aria-controls={isFilterMenuOpen ? "filter-menu" : undefined}
        aria-expanded={isFilterMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
      >
        <FilterListIcon />
      </IconButton>
      <FilterMenu
        id="filter-menu"
        anchorEl={document.getElementById("filter-button")}
        open={isFilterMenuOpen}
        onClose={() => setIsFilterMenuOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack sx={{ p: 1 }} spacing={1}>
          {filterOptions?.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                name={option.type}
                checked={option.isChecked === filters[option.type]}
                onChange={(e) => {
                  handleOnChange(e, option);
                }}
                control={<Checkbox disableRipple color="primary" />}
                label={<Typography variant="h6">{option.label}</Typography>}
                labelPlacement="end"
              />
            );
          })}
          <Button variant="contained" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
          <Button variant="outlined" onClick={handleOnResetFilter}>
            Reset Filters
          </Button>
        </Stack>
      </FilterMenu>
    </>
  );
};

export default Filter;

PropTypes.Filter = {
  handleFilter: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
};
