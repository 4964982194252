import CheckboxSelect from "./CheckboxSelect";
import { Typography, FormGroup } from "@mui/material";
import { parseInt } from "lodash";

const MultiSelect = ({
  heading,
  options,
  onChange,
  values,
  labels,
  subHeadingEl,
  hasError,
  onFocus,
}) => {
  const handleChange = (data) => {
    const { value, checked, label } = data;
    let newValues = [...values];
    let newLabels = labels ? [...labels] : null;

    if (checked) {
      newValues.push(parseInt(value));
      labels && newLabels.push(label);
    } else {
      newValues = values.filter(
        (currentValue) => currentValue !== parseInt(value)
      );
      if (labels)
        newLabels = labels.filter((currentLabel) => currentLabel !== label);
    }

    onChange(newValues, newLabels);
  };

  return (
    <>
      <Typography component="h6" variant="brandonTitle">
        {heading}
      </Typography>
      {subHeadingEl}
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.625, // 13px
        }}
      >
        {options?.map((option) => {
          const isChecked = values?.some((id) => id === option.id);
          return (
            <CheckboxSelect
              value={option.id}
              key={option.label}
              label={option.label}
              isChecked={isChecked}
              onChange={handleChange}
              hasError={hasError}
              onFocus={onFocus}
            />
          );
        })}
      </FormGroup>
    </>
  );
};

export default MultiSelect;
