import { useState, useEffect } from "react";
import axios from "src/utils/axios";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import getPageContent from "./pageContent";

import {
  ContentWrapper,
  FormWrapper,
  Heading,
  RowSelect,
  SingleRadioSelect,
  Textarea,
  SubmitButton,
  RobynDisclaimer,
  ErrorBanner,
  FolFooter,
} from "src/components/Forms/common";

const validationSchema = Yup.object().shape({
  serviceSupportiveId: Yup.number().required("Required").typeError("Required"),
  agreeInformationId: Yup.number().required("Required").typeError("Required"),
  agreeCommunicationId: Yup.number().required("Required").typeError("Required"),
  agreeWellbeingId: Yup.number().required("Required").typeError("Required"),
  agreeMoveForwardId: Yup.number().required("Required").typeError("Required"),
  feedback: Yup.string(),
});

const initialFormOptions = {
  serviceSupportive: [
    {
      id: 1,
      label: "Agree",
    },
    {
      id: 2,
      label: "Disagree",
    },
  ],
};

const initialValues = {
  agreeInformationId: null,
  agreeCommunicationId: null,
  agreeWellbeingId: null,
  agreeMoveForwardId: null,
  serviceSupportiveId: null,
  feedback: "",
};

const getFormOptions = async () => {
  try {
    const { data: formOptions } = await axios.get("/api/crm/form-options", {
      params: {
        formName: "survey",
      },
    });
    return formOptions;
  } catch (error) {
    throw error;
  }
};

const getClient = async (clientId) => {
  try {
    const { data: client } = await axios.get("/api/clients/public", {
      params: {
        clientId,
      },
    });
    return client;
  } catch (error) {
    throw error;
  }
};

// A Public route that returns a survey object that includes isCompleted (Bool)
// to make certain that it is the correct survey pass both the clientId and surveyId
const getSurvey = async ({ clientId, surveyId }) => {
  try {
    const { data: survey } = await axios.get("/api/clients/public/survey", {
      params: {
        clientId,
        surveyId,
      },
    });
    return survey;
  } catch (error) {
    throw error;
  }
};

const SurveyForm = ({ clientId, surveyId }) => {
  const [formStatus, setFormStatus] = useState("IS_LOADING");
  const [formOptions, setFormOptions] = useState({});
  const [survey, setSurvey] = useState(null);
  const [pageContent, setPageContent] = useState(null);

  const handleSubmit = async ({ serviceSupportiveId, ...rest }) => {
    try {
      setFormStatus("IS_LOADING");
      const isServiceSupportive = serviceSupportiveId === 1 ? true : false;

      const formValues = {
        ...rest,
        isServiceSupportive,
      };

      await axios.put(
        `/api/clients/${clientId}/survey/${surveyId}`,
        formValues
      );

      setFormStatus("SUCCESS");
    } catch (error) {
      console.error(error.message);
      setFormStatus("SUBMIT_ERROR");
    }
  };

  // [STEP 1]: GET SURVEY, CLIENT AND FORM OPTIONS and SET SURVEY STATE
  useEffect(() => {
    (async () => {
      try {
        // If there is no clientId or surveyId show load error
        if (!clientId && !surveyId) {
          setFormStatus("LOAD_ERROR");
          return;
        }

        const client = await getClient(clientId);

        // Show sign up notification if client is not found
        if (!client) {
          setFormStatus("SHOW_SIGN_UP");
          return;
        }

        const survey = await getSurvey({ clientId, surveyId });

        if (!survey) {
          setFormStatus("LOAD_ERROR");
          return;
        }

        if (survey.isCompleted) {
          setFormStatus("FORM_ALREADY_SUBMITTED");
          return;
        }

        setSurvey(survey);
      } catch (error) {
        console.error(error.message);
        setFormStatus("LOAD_ERROR");
      }
    })();

    return () => {
      setFormStatus("IS_LOADING");
      setSurvey(null);
    };
  }, [clientId, surveyId]);

  /* [STEP 2]: Once there is a survey object:
   * Get Form Options > Set Form Options
   * Get page content (Customer Satisfaction or Virtual Session) > Set Page Content
   * Set Form Status to SHOW_FORM
   */
  useEffect(() => {
    if (!survey) return;
    (async () => {
      try {
        const newFormOptions = await getFormOptions();
        const currentFormOptions = { ...initialFormOptions, ...newFormOptions };
        setFormOptions(currentFormOptions);

        // Get page content based on survey type (Customer Satisfaction or Virtual Session)
        const pageContent = await getPageContent(survey.type);

        setPageContent(pageContent);

        setFormStatus("SHOW_FORM");
      } catch (error) {
        console.error(error.message);
        setFormStatus("LOAD_ERROR");
      }
    })();

    return () => {
      setFormOptions({});
      setPageContent(null);
      setFormStatus("IS_LOADING");
    };
  }, [survey]);

  return (
    <>
      <ContentWrapper>
        <FormWrapper formName="survey" formStatus={formStatus}>
          {Boolean(pageContent) && (
            <>
              <Heading
                title={pageContent.heading.title}
                subtitle={pageContent.heading.subtitle}
                sx={{
                  mb: 1,
                  "& .title": {
                    textAlign: "center",
                  },
                  "& .subtitle": {
                    textAlign: "center",
                    maxWidth: "400px",
                  },
                }}
              />
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  setFieldValue,
                  errors,
                  handleSubmit,
                  isValid,
                  setFieldError,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ gap: 1 }}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading={pageContent.serviceSupportive.heading}
                            value={values.serviceSupportiveId}
                            onChange={(value) => {
                              setFieldValue("serviceSupportiveId", value);
                              setFieldError("serviceSupportiveId", undefined);
                            }}
                            options={formOptions.serviceSupportive}
                            hasError={Boolean(errors.serviceSupportiveId)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RowSelect
                            errors={errors}
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                              setFieldError(name, undefined);
                            }}
                            title={pageContent.agreeLevel.title}
                            options={formOptions?.agreeLevel || []}
                            rows={[
                              {
                                name: "agreeInformationId",
                                label:
                                  pageContent.agreeLevel.agreeInformation.label,
                              },
                              {
                                name: "agreeCommunicationId",
                                label:
                                  pageContent.agreeLevel.agreeCommunication
                                    .label,
                              },
                              {
                                name: "agreeWellbeingId",
                                label:
                                  pageContent.agreeLevel.agreeWellbeing.label,
                              },
                              {
                                name: "agreeMoveForwardId",
                                label:
                                  pageContent.agreeLevel.agreeMoveForward.label,
                              },
                            ]}
                          />
                        </Grid>
                        <Grid item container xs={12}>
                          <Typography variant="brandonTitle">
                            {pageContent.feedback}
                          </Typography>
                          <Textarea
                            label=""
                            name="feedback"
                            value={values.feedback}
                            onChange={(e) => {
                              setFieldValue("feedback", e.target.value);
                              setFieldError("feedback", undefined);
                            }}
                            hasError={Boolean(errors.feedback)}
                          />
                        </Grid>
                        {!isValid && (
                          <Grid item xs={12}>
                            <ErrorBanner />
                          </Grid>
                        )}

                        <Grid container justifyContent="center" item xs={12}>
                          <SubmitButton disabled={!isValid} />{" "}
                        </Grid>
                        <Grid item xs={12}>
                          <RobynDisclaimer
                            hasHeading
                            veevaCode={
                              pageContent?.hasRobynDisclaimerVeevaCode &&
                              pageContent.veevaCode
                            }
                          />
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </FormWrapper>
      </ContentWrapper>
      <FolFooter veevaCode={pageContent?.veevaCode || ""} />
    </>
  );
};

export default SurveyForm;
