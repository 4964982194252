import { Card, Grid, CardContent } from "@mui/material";
import DisplayText from "src/components/common/Crm/DisplayText";
import DisplayChips from "src/components/common/Crm/DisplayChips";

const Stats = ({
  ttcDuration,
  ethnicIdentities,
  genderIdentity,
  age,
  ageRange,
  reDuration,
  clinicAmount,
  ivfCycle,
  miscarriageAmount,
  liveBirthAmount,
  pregnancyAmount,
  activePhysicians,
  otherActivePhysician,
  hasInsurance,
  wellnessStatuses,
  resources,
  currentTreatments,
  priorDiagnosises,
  otherPriorDiagnosis,
  journeyDifficulties,
  insuranceBenefits,
  bmi,
  height,
  weight,
}) => {
  return (
    <Card elevation={0} square variant="outlined">
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <DisplayText primary={"Age Range"} secondary={ageRange} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary={"Age"} secondary={age} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary={"TTC Duration"} secondary={ttcDuration} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary="BMI" secondary={bmi} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary="Height" secondary={height} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary="Weight" secondary={weight} />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <DisplayText primary="Gender Identity" secondary={genderIdentity} />
          </Grid>
          <Grid item md={4} xs={6}>
            <DisplayChips
              label={"Ethnic Identities"}
              values={ethnicIdentities}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText
              primary="# of Pregnancies"
              secondary={pregnancyAmount}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText
              primary={"# of Live Births"}
              secondary={liveBirthAmount}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText
              primary="# of Miscarriages"
              secondary={miscarriageAmount}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={4} xs={6}>
            <DisplayChips
              label={"Active Physicians"}
              values={activePhysicians}
              otherOption={otherActivePhysician}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary="RE Duration" secondary={reDuration} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary="# of Clinics" secondary={clinicAmount} />
          </Grid>
          <Grid item md={2} xs={6}>
            <DisplayText primary={"IVF Cycles"} secondary={ivfCycle} />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2} xs={6}>
            <DisplayText primary="Has Insurance" secondary={hasInsurance} />
          </Grid>
          <Grid item md={3} xs={6}>
            <DisplayChips
              label={"Insurance Benefits"}
              values={insuranceBenefits}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <DisplayChips
              label={"Current Treatments"}
              values={currentTreatments}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <DisplayChips
              label={"Prior Diagnosis"}
              values={priorDiagnosises}
              otherOption={otherPriorDiagnosis}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={3} xs={6}>
            <DisplayChips
              label={"Fertility Services/Resources"}
              values={resources}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <DisplayChips label={"Wellness Status"} values={wellnessStatuses} />
          </Grid>
          <Grid item md={3} xs={6}>
            <DisplayChips
              label={"Journey Difficulties"}
              values={journeyDifficulties}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stats;
