import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  mindset: Yup.number().required("Required").typeError("Required"),
  physical: Yup.number().required("Required").typeError("Required"),
  emotional: Yup.number().required("Required").typeError("Required"),
  social: Yup.number().required("Required").typeError("Required"),
  financial: Yup.number().required("Required").typeError("Required"),
  isSeeingReId: Yup.number().required("Required").typeError("Required"),

  // IS SEEING RE:
  journeyUpdateEventId: Yup.number().when("isSeeingReId", {
    is: (isSeeingReId) => {
      return isSeeingReId === 1;
    },
    then: Yup.number().required("Required").typeError("Required"),
    otherwise: Yup.number().nullable().optional(),
  }),
  otherJourneyUpdateEvent: Yup.string().when("journeyUpdateEventId", {
    is: (journeyUpdateEventId) => {
      return journeyUpdateEventId === 20;
    },
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),

  //  IS NOT SEEING RE:
  notAtReReasonIds: Yup.array().when("isSeeingReId", {
    is: (isSeeingReId) => {
      return isSeeingReId === 2;
    },
    then: Yup.array().test("notAtReReasonIds", "Required", function (values) {
      return values.length !== 0;
    }),
    otherwise: Yup.array(),
  }),
  otherNotAtReReason: Yup.string().when("notAtReReasonIds", {
    is: (notAtReReasonIds) => {
      const check = notAtReReasonIds?.some((id) =>
        [5].some((value) => id === value)
      );

      if (check) {
        return true;
      }
    },
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),
});

export default validationSchema;
