import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ChatContext } from "src/context/ChatContext";
import {
  useSocketEvents,
  useSocketRooms,
  SOCKET_EVENTS,
  SOCKET_ROOMS,
} from "src/utils/socket";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageItem from "../MessageItem";
import { Stack } from "@mui/material";
import LoadingContent from "src/components/common/LoadingContent";
import { has } from "lodash";

const MessageList = ({ selectedPhoneNumber }) => {
  // Initial State:
  const startPage = 1;
  const messagePageSize = 20;
  // Context
  const { selectedMessage, handleGetMessages } = useContext(ChatContext);
  // Refs
  const infiniteScrollRef = useRef(null);
  const containerRef = useRef(null);
  const messagesRef = useRef([]);
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [queryPhoneNumber, setQueryPhoneNumber] = useState(null);
  const [nextPage, setNextPage] = useState(startPage);
  const [hasMore, setHasMore] = useState(false);

  const phoneNumber = selectedMessage?.queryPhoneNumber || selectedPhoneNumber;

  const handleFetchMoreMessages = async () => {
    console.log("Fetching more messages");
    // Find Messages belonging the contact
    const { messages: queryMessages } = await handleGetMessages({
      phoneNumber: queryPhoneNumber,
      pageNumber: nextPage,
      limit: messagePageSize,
    });
    setMessages((prevMessages) => [...prevMessages, ...queryMessages]);
    setHasMore(queryMessages.length > 0);
    setNextPage(nextPage + 1);
  };

  const scrollToBottom = () => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.scrollTop =
        infiniteScrollRef.current.scrollHeight;
    }
  };

  // SOCKET EVENT HANDLER FOR NEW MESSAGES:
  const socketHandleNewMessage = (message) => {
    const newMessages = [message, ...messagesRef.current];
    setMessages(newMessages);
  };

  // Fetch messages on mount
  useEffect(() => {
    (async () => {
      // Check if we're viewing messages for a selected phone number
      setIsLoading(true);
      if (phoneNumber) {
        // Find Messages belonging the contact
        const { messages } = await handleGetMessages({
          phoneNumber: phoneNumber,
          messageId: selectedMessage?.id,
          pageNumber: startPage,
          limit: messagePageSize,
        });

        setMessages(messages);
        setQueryPhoneNumber(phoneNumber);
        setNextPage(startPage + 1);
        setIsLoading(false);
        setHasMore(messages.length > 0);
        scrollToBottom();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhoneNumber, selectedMessage]);

  // Gotta use a ref to ensure smooth updates via sockets
  // Manage keeping the message ref up to date
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (isLoading) return;
    containerRef.current?.scrollIntoView({
      block: "nearest",
      inline: "center",
    });
  }, [isLoading, selectedMessage]);

  useSocketRooms(
    [
      {
        roomName: SOCKET_ROOMS.JOIN_CONVERSATION,
        roomId: selectedMessage?.queryPhoneNumber || selectedPhoneNumber,
      },
    ],
    phoneNumber
  );

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.MESSAGE,
        handler: socketHandleNewMessage,
      },
    ],
    phoneNumber
  );

  return (
    <Stack
      direction="column-reverse"
      id="scrollableDiv"
      ref={infiniteScrollRef}
      sx={{
        overflow: "auto",
        height: "100%",
      }}
    >
      <InfiniteScroll
        dataLength={messages.length}
        next={handleFetchMoreMessages}
        inverse
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        loader={isLoading ? <LoadingContent /> : null}
      >
        <Stack direction="column-reverse" useFlexGap>
          {messages.map((message, index) => (
            <div
              ref={message?.id === selectedMessage?.id ? containerRef : null}
              key={message?.id || index}
            >
              <MessageItem key={index} message={message} />
            </div>
          ))}
        </Stack>
      </InfiniteScroll>
    </Stack>
  );
};

MessageList.propTypes = {
  selectedPhoneNumber: PropTypes.string,
};

export default MessageList;
