import { Box, Grid, Typography, IconButton, SvgIcon } from "@mui/material";
import { Trash as DeleteIcon, Edit as EditIcon } from "react-feather";

import moment from "moment";

const TimelineItemContainer = ({
  hasEditAction,
  hasDeleteAction,
  handleSetEditId,
  handleDeleteItem,
  currentEditId,
  createdAt,
  index,
  children,
  itemId,
  itemType,
}) => {
  const editId = index + 1;

  return (
    <Box sx={{ width: "100%", mb: 1 }} id={`journey-item-${editId}`}>
      <Grid container>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="primary.main" variant="caption" flex={3}>
              {moment.utc(createdAt).format("L")}
            </Typography>
          </Grid>

          <Grid item container justifyContent="flex-end" xs={2} spacing={1}>
            {hasEditAction && (
              <Grid item xs={6}>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={Boolean(currentEditId)}
                  onClick={() => handleSetEditId(editId)}
                >
                  <SvgIcon>
                    <EditIcon size={18} />
                  </SvgIcon>
                </IconButton>
              </Grid>
            )}
            {hasDeleteAction && (
              <Grid item xs={6}>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={Boolean(currentEditId)}
                  onClick={() =>
                    handleDeleteItem({
                      itemId,
                      itemType,
                    })
                  }
                >
                  <SvgIcon>
                    <DeleteIcon size={18} />
                  </SvgIcon>
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Box>
  );
};

export default TimelineItemContainer;
