import propTypes from "prop-types";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import ToolTip from "src/components/common/ToolTip";

/**
 *  A dropdown component that allows single selections.
 * @param {string} id - The id of the dropdown.
 * @param {string} label - The label and placeholder of the dropdown.
 *  @param {number} value - The selected value. (id)
 * @param {object} options - The options to display in the dropdown.
 * @param {Function} onChange - The function to set the selected value. (id)
 * @param {string} variant - The variant of the dropdown. (outlined or standard)
 * @param {object} rest - The rest of the props.
 *
 */
const SingleSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  variant = "outlined",
  hasError,
  ...rest
}) => {
  return (
    <FormControl fullWidth variant={variant} sx={{ height: "100%" }}>
      <InputLabel id={`${id}-label`} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        label={label}
        labelId={`${id}-label`}
        variant={variant}
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        error={hasError}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <ToolTip title={option?.label || ""} placement="top">
              <span>{option.shortLabel || option.label}</span>
            </ToolTip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;

SingleSelect.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  options: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.oneOfType([propTypes.string, propTypes.number]),
      label: propTypes.string,
      shortLabel: propTypes.string,
    })
  ).isRequired,
  onChange: propTypes.func.isRequired,
  variant: propTypes.oneOf(["outlined", "standard"]),
  hasError: propTypes.bool,
  rest: propTypes.any,
};
