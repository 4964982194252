import { useState, useEffect } from "react";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { FormControlLabel, Switch } from "@mui/material";

const AwayToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const handleUpdateSetting = async (e) => {
    const isChecked = e.target.checked;

    try {
      const { data: settings } = await axios.put("/api/settings", {
        isOpen: isChecked,
      });

      setIsOpen(settings.isOpen);
    } catch (error) {
      toast.error(`Error updating setting`);
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data: settings } = await axios.get("/api/settings");

      setIsOpen(settings.isOpen);
    } catch (error) {
      toast.error(`Error getting settings`);
    }
  };

  const socketHandleUpdateSettings = (settings) => {
    setIsOpen(settings.isOpen);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    (async () => {
      await handleGetSettings();
    })();

    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_SETTINGS,
        handler: socketHandleUpdateSettings,
      },
    ],
    isMounted
  );

  return (
    <FormControlLabel
      sx={{
        "& .MuiFormControlLabel-label": {
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "20px",
          color: "text-primary",
        },
      }}
      onChange={handleUpdateSetting}
      checked={isOpen}
      control={
        <Switch
          sx={{
            "& .MuiSwitch-colorSecondary.Mui-checked": {
              color: "error.main",
            },
          }}
          checked={isOpen}
        />
      }
      label="Set to Open"
    />
  );
};

export default AwayToggle;
