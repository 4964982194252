import { useRef } from "react";
import Page from "src/components/common/Page";
import ThankYou from "src/components/ThankYou";
import FolFooter from "src/components/Forms/common/FolFooter";
import ClientHeader from "src/components/common/ClientHeader";

const Confirmation = () => {
  const pageRef = useRef(null);

  return (
    <Page
      sx={{
        position: "relative",
        backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      title="Thank You"
      ref={pageRef}
    >
      <ClientHeader />
      <ThankYou />

      <FolFooter />
    </Page>
  );
};

export default Confirmation;
