import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import delay from "delay";
import queryString from "query-string";

import axios from "src/utils/axios";
import submitToGADataLayer from "src/utils/submitToGADataLayer";
import validationSchema from "./validationSchema";
import { AGE_QUALIFICATIONS, TTC_QUALIFICATIONS } from "src/utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { OptionContext } from "src/context/OptionContext";

import {
  MultiSelect,
  PhoneInput,
  SingleRadioSelect,
  SubmitButton,
  TextInput,
  HiddenLogicComponent,
  ContentWrapper,
  Heading,
  FormWrapper,
  ErrorBanner,
  RobynDisclaimer,
  RobynFerringDisclaimer,
} from "src/components/Forms/common";
import getIdFromLabel from "src/utils/getIdFromLabel";

const initialValues = {
  // Personal Information:
  firstName: "",
  email: "",
  phoneNumber: "",
  zipCode: "",
  // Journey:
  journeyStageId: null,
  journeyStagLabel: "",
  // Trying to Conceive Duration:
  ttcDurationId: null,
  ttcDurationLabel: "",
  /// Physician:
  activePhysicianIds: [],
  activePhysicianLabels: [],
  // IS SEEING RE:
  isSeeingRe: null,
  journeyUpdateEventId: null,
  otherJourneyUpdateEvent: "",
  notAtReReasonIds: [],
  otherNotAtReReason: "",
  // Demographics:
  ageRangeId: null,
  ethnicIdentityIds: [],
  genderIdentityId: null,
  genderIdentityLabel: "",
  // Source:
  referralId: null,
  otherReferral: "",
  // Optin:
  optinId: null,
  // Terms and Conditions:
  tAndCId: null,
};

const initialFormOptions = {
  reCheck: [
    {
      id: 1,
      label:
        "I was seeing a fertility specialist, but am no longer seeing them ",
    },
    {
      id: 2,
      label: "I haven't seen a fertility specialist yet",
    },
  ],
  optin: [
    {
      id: 1,
      label: "Yes, please!",
    },
    {
      id: 2,
      label: "No, thank you.",
    },
  ],
  tAndC: [
    {
      id: 1,
      label: "I accept",
    },
    {
      id: 2,
      label: "I don't accept",
    },
  ],
};

const getFormOptions = async () => {
  try {
    const { data: formOptions } = await axios.get("/api/crm/form-options", {
      params: {
        formName: "signup",
      },
    });
    return formOptions;
  } catch (error) {
    throw error;
  }
};

const Form = ({ search }) => {
  const [formStatus, setFormStatus] = useState("IS_LOADING");
  const history = useHistory();
  const [formOptions, setFormOptions] = useState({});
  const { journeyUpdateEventOptions } = useContext(OptionContext);

  const handleSubmit = async (values) => {
    try {
      setFormStatus("IS_LOADING");

      const params = queryString.parse(search);

      const isAgeQualified = AGE_QUALIFICATIONS.includes(values.ageRangeLabel);
      const isTTCQualified = TTC_QUALIFICATIONS.includes(
        values.ttcDurationLabel
      );

      const isQualified = isAgeQualified && isTTCQualified;

      const isTest =
        values.firstName.toLowerCase().includes("test") ||
        process.env.REACT_APP_ENV === "staging";

      const utm = {
        source: params?.utm_source,
        medium: params?.utm_medium,
        campaign: params?.utm_campaign,
        term: params?.utm_term,
        content: params?.utm_content,
        signupSource: params?.signup_source || "for",
        signupUrl: params?.signup_url || window.location.href,
      };

      const isOptin = values.optinId === 1;

      delete values.journeyStageLabel;
      delete values.ageRangeLabel;
      delete values.tAndCId;
      delete values.optinId;

      const journeyUpdateEvents = [];

      const notAtReId = getIdFromLabel({
        options: journeyUpdateEventOptions,
        label: "Not at RE",
      });

      const atReId = getIdFromLabel({
        options: journeyUpdateEventOptions,
        label: "At RE",
      });

      const pregnantId = getIdFromLabel({
        options: journeyUpdateEventOptions,
        label: "Pregnant",
      });

      if (values.isSeeingRe) {
        journeyUpdateEvents.push({
          id: atReId,
        });

        journeyUpdateEvents.push({
          id: values.journeyUpdateEventId,
          otherJourneyUpdateEvent: values.otherJourneyUpdateEvent,
        });
      } else {
        // If isSeeingRe is false add Not at RE event
        journeyUpdateEvents.push({
          id: notAtReId,
          notAtReReasonIds: values.notAtReReasonIds,
          otherNotAtReReason: values.otherNotAtReReason,
        });

        if (
          values.notAtReReasonIds.includes(
            getIdFromLabel({
              label: "Pregnancy, transferred care to OBGYN",
              options: formOptions.notAtReReason,
            })
          )
        ) {
          journeyUpdateEvents.push({
            id: pregnantId,
          });
        }
      }

      const adjustedValues = {
        ...values,
        isOptin,
        isQualified,
        isTest,
        signupUrl: window.location.href,
        signupSource: "for",
        journeyUpdateEvents,
        ...utm,
      };

      const user = await axios.post("/api/clients/sign-up", adjustedValues);

      submitToGADataLayer({
        event: "Purchase",
        userId: user.id,
        isTest,
      });

      await delay(2000);

      if (isQualified) {
        submitToGADataLayer({
          event: "Qualified Client",
          isTest,
        });
      }

      history.push("/thank-you");
    } catch (error) {
      console.error("error on submit", error.message);
      if (error.response.status === 409) {
        setFormStatus("PHONE_NUMBER_IN_USE_ERROR");
        return;
      }
      setFormStatus("SUBMIT_ERROR");
    }
  };

  const handleFieldCompleted = async (fieldName) => {
    submitToGADataLayer({
      event: "form_field_completed",
      formFieldName: fieldName,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const newFormOptions = await getFormOptions();

        const currentFormOptions = {
          ...initialFormOptions,

          ...newFormOptions,
        };
        setFormOptions(currentFormOptions);
        setFormStatus("SHOW_FORM");
      } catch (error) {
        console.log(error.message);
        setFormStatus("LOAD_ERROR");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper>
      <FormWrapper formName="sign-up" formStatus={formStatus}>
        <>
          <Heading
            title={"Sign up to text!"}
            subtitle="To help personalize the experience with your fertility coach, we
                just need a little more information from you."
          />

          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              errors,
              handleSubmit,
              setFieldError,
              isValid,
            }) => {
              const setField = (fieldName, value) => {
                setFieldValue(fieldName, value);
                setFieldError(fieldName, undefined);
              };

              const clearFields = (fieldNames) => {
                fieldNames.forEach((fieldName) => {
                  setFieldValue(fieldName, initialValues[fieldName]);
                  setFieldError(fieldName, undefined);
                });
              };

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ gap: "2.125rem" }}>
                    <Grid container spacing={2} item>
                      {/* First Name */}
                      <Grid item xs={12}>
                        <TextInput
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          error={Boolean(errors.firstName)}
                          onChange={(e) => {
                            setField("firstName", e.target.value);
                          }}
                          onBlur={() => handleFieldCompleted("firstName")}
                          variant="outlined"
                          errorText={errors.firstName}
                        />
                      </Grid>
                      {/* Email */}
                      <Grid item xs={12}>
                        <TextInput
                          label="Email Address"
                          name="email"
                          value={values.email}
                          onChange={(e) => {
                            setField("email", e.target.value);
                          }}
                          error={Boolean(errors.email)}
                          variant="outlined"
                          onBlur={() => handleFieldCompleted("email")}
                          errorText={errors.email}
                        />
                      </Grid>
                      {/* PHONE */}
                      <Grid item xs={12}>
                        <PhoneInput
                          variant="outlined"
                          fullWidth
                          label="Mobile Number"
                          name="phoneNumber"
                          value={values?.phoneNumber}
                          onChange={(phoneNumber) => {
                            setField("phoneNumber", phoneNumber);
                          }}
                          errorText={errors.phoneNumber}
                          error={Boolean(errors.phoneNumber)}
                          onBlur={() => handleFieldCompleted("phoneNumber")}
                        />
                      </Grid>
                      {/* ZIP Code */}
                      <Grid item xs={12}>
                        <TextInput
                          variant="outlined"
                          type="number"
                          label="Zip Code"
                          name="zipCode"
                          value={values.zipCode}
                          error={Boolean(errors.zipCode)}
                          errorText={errors.zipCode}
                          onChange={(e) => {
                            setField("zipCode", e.target.value);
                          }}
                          onBlur={() => {
                            handleFieldCompleted("zipCode");
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* Journey Stage */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="What does your fertility journey look like?"
                        value={values.journeyStageId}
                        options={formOptions.journeyStage}
                        onChange={(journeyStageId, label) => {
                          setField("journeyStageId", journeyStageId);
                          setField("journeyStageLabel", label);
                          handleFieldCompleted("journeyStage");
                        }}
                        hasError={Boolean(errors.journeyStageId)}
                      />
                    </Grid>
                    {/* Trying to conceive  */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="How long have you been trying to conceive or grow your family?"
                        value={values.ttcDurationId}
                        onChange={(ttcDurationId, label) => {
                          setField("ttcDurationId", ttcDurationId);
                          setField("ttcDurationLabel", label);

                          handleFieldCompleted("ttcDuration");
                        }}
                        options={formOptions.ttcDuration}
                        hasError={Boolean(errors.ttcDurationId)}
                      />
                    </Grid>

                    {/* Active Physicians */}
                    <Grid item xs={12}>
                      <MultiSelect
                        heading="Which of the following active physicians do you have?"
                        labels={values.activePhysicianLabels}
                        values={values.activePhysicianIds}
                        onChange={(selectValues, labels) => {
                          setField("activePhysicianIds", selectValues);
                          setField("activePhysicianLabels", labels);

                          // OPTION: 3 ("Fertility Specialist or Reproductive Endocrinologist (RE)") is selected
                          const isSeeingRe = selectValues.some(
                            (id) => id === 3
                          );

                          setField("isSeeingRe", isSeeingRe);

                          handleFieldCompleted("activePhysician");
                        }}
                        options={formOptions.activePhysician}
                        hasError={Boolean(errors.activePhysicianIds)}
                      />
                    </Grid>

                    {/* Logic based on Active Physicians Previous Section */}
                    {Boolean(values.activePhysicianIds.length) && (
                      <>
                        {/* IF "Fertility Specialist or Reproductive Endocrinologist (RE)" IS selected */}
                        <HiddenLogicComponent
                          value={values.isSeeingRe}
                          clearFields={clearFields}
                          names={["journeyUpdateEventId"]}
                        >
                          <Grid item xs={12}>
                            <SingleRadioSelect
                              heading="Where are you on the fertility treatment journey?"
                              value={values.journeyUpdateEventId}
                              onChange={(value) => {
                                setField("journeyUpdateEventId", value);
                                setFieldError(
                                  "journeyUpdateEventId",
                                  undefined
                                );

                                handleFieldCompleted("journeyUpdateEvent");
                              }}
                              options={formOptions.journeyUpdateEvent}
                              hasError={Boolean(errors.journeyUpdateEventId)}
                            />
                          </Grid>
                          <HiddenLogicComponent
                            value={values.journeyUpdateEventId === 31}
                            clearFields={clearFields}
                            names={["otherJourneyUpdateEvent"]}
                          >
                            <Grid item xs={12}>
                              <TextInput
                                label="Please tell us where you are on the fertility treatment journey"
                                name="otherJourneyUpdateEvent"
                                value={values.otherJourneyUpdateEvent}
                                onChange={(e) => {
                                  setField(
                                    "otherJourneyUpdateEvent",
                                    e.target.value
                                  );
                                }}
                                error={Boolean(errors.otherJourneyUpdateEvent)}
                                variant="outlined"
                                onBlur={() =>
                                  handleFieldCompleted(
                                    "otherJourneyUpdateEvent"
                                  )
                                }
                              />
                            </Grid>
                          </HiddenLogicComponent>
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "Fertility Specialist or Reproductive Endocrinologist (RE)" IS selected */}

                        {/* IF "Fertility Specialist or Reproductive Endocrinologist (RE)" is NOT selected */}
                        <HiddenLogicComponent
                          value={!values.isSeeingRe}
                          clearFields={clearFields}
                          names={["notAtReReasonIds"]}
                        >
                          <Grid item xs={12}>
                            <MultiSelect
                              heading="Why are you not seeing a fertility specialist?"
                              onChange={(values) => {
                                setField("notAtReReasonIds", values);

                                handleFieldCompleted("notAtReReason");
                              }}
                              values={values.notAtReReasonIds}
                              options={formOptions.notAtReReason}
                              hasError={Boolean(errors.notAtReReasonIds)}
                            />
                          </Grid>
                          <HiddenLogicComponent
                            value={values.notAtReReasonIds.includes(5)}
                            clearFields={clearFields}
                            names={["otherNotAtReReason"]}
                          >
                            <Grid item xs={12}>
                              <TextInput
                                label="Please tell us why you are not seeing a fertility specialist"
                                name="otherNotAtReReason"
                                value={values.otherNotAtReReason}
                                onChange={(e) => {
                                  setField(
                                    "otherNotAtReReason",
                                    e.target.value
                                  );
                                }}
                                error={Boolean(errors.otherNotAtReReason)}
                                variant="outlined"
                                onBlur={() =>
                                  handleFieldCompleted("otherNotAtReReason")
                                }
                              />
                            </Grid>
                          </HiddenLogicComponent>
                        </HiddenLogicComponent>
                      </>
                    )}

                    {/* AGE */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Age"
                        value={values.ageRangeId}
                        onChange={(value, label) => {
                          setField("ageRangeId", value);
                          setField("ageRangeLabel", label);

                          handleFieldCompleted("ageRange");
                        }}
                        options={formOptions.ageRange}
                        hasError={Boolean(errors.ageRangeId)}
                      />
                    </Grid>
                    {/* ETHNIC IDENTITY */}
                    <Grid item xs={12}>
                      <MultiSelect
                        heading="Ethnic Identity"
                        values={values.ethnicIdentityIds}
                        onChange={(values) => {
                          setField("ethnicIdentityIds", values);

                          handleFieldCompleted("ethnicIdentity");
                        }}
                        options={formOptions.ethnicIdentity}
                        hasError={Boolean(errors.ethnicIdentityIds)}
                      />
                    </Grid>
                    {/* GENDER IDENTITY */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Gender Identity"
                        tooltip="With which gender identity do you most identify?"
                        value={values.genderIdentityId}
                        onChange={(value, label) => {
                          setField("genderIdentityId", value);
                          setField("genderIdentityLabel", label);

                          handleFieldCompleted("genderIdentity");
                        }}
                        options={formOptions.genderIdentity}
                        hasError={Boolean(errors.genderIdentityId)}
                      />
                    </Grid>
                    {/* HOW DID YOU HEAR ABOUT US?  */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="How did you hear about us?"
                        value={values.referralId}
                        onChange={(value) => {
                          setField("referralId", value);

                          handleFieldCompleted("referralId");
                        }}
                        options={formOptions.referral}
                        hasError={Boolean(errors.referralId)}
                      />
                    </Grid>
                    <HiddenLogicComponent
                      clearFields={clearFields}
                      value={values.referralId === 9}
                      names={["otherReferral"]}
                    >
                      <Grid item xs={12}>
                        <TextInput
                          label="Please tell us how you heard about us"
                          name="otherReferral"
                          value={values.otherReferral}
                          onChange={(e) => {
                            setField("otherReferral", e.target.value);
                          }}
                          error={Boolean(errors.otherReferral)}
                          variant="outlined"
                          onBlur={() => handleFieldCompleted("otherReferral")}
                        />
                      </Grid>
                    </HiddenLogicComponent>
                    {/* OPTIN TO UPDATES */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Would you like to receive updates and information via email?"
                        value={values.optinId}
                        onChange={(value) => {
                          setField("optinId", value);

                          handleFieldCompleted("optin");
                        }}
                        options={formOptions.optin}
                        hasError={Boolean(errors.optinId)}
                      />
                    </Grid>
                    {/* IF opted in or out of Updates, show Terms and Conditions: */}
                    <HiddenLogicComponent
                      value={values.optinId}
                      clearFields={clearFields}
                      names={["tAndCId"]}
                    >
                      {values.optinId === 1 ? (
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Terms & Conditions"
                            subHeadingEl={<RobynFerringDisclaimer />}
                            value={values.tAndCId}
                            onChange={(value) => {
                              setField("tAndCId", value);

                              handleFieldCompleted("tAndC");
                            }}
                            options={formOptions.tAndC}
                            hasError={Boolean(errors.tAndCId)}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Terms & Conditions"
                            subHeadingEl={<RobynDisclaimer />}
                            value={values.tAndCId}
                            onChange={(value) => {
                              setField("tAndCId", value);

                              handleFieldCompleted("tAndC");
                            }}
                            options={formOptions.tAndC}
                            hasError={Boolean(errors.tAndCId)}
                          />
                        </Grid>
                      )}
                    </HiddenLogicComponent>

                    {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                      <Grid item xs={12} container justifyContent="center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={(token) => {
                            setFieldValue("recaptcha", token);
                            setFieldError("recaptcha", undefined);
                          }}
                        />
                      </Grid>
                    )}

                    {!isValid && (
                      <Grid item xs={12}>
                        <ErrorBanner />
                      </Grid>
                    )}

                    <Grid item container justifyContent="center" xs={12}>
                      <SubmitButton disabled={!isValid} />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </>
      </FormWrapper>
    </ContentWrapper>
  );
};

export default Form;
