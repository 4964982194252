import { Button } from "@mui/material";

const SubmitButton = ({ disabled }) => {
  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      size="large"
      sx={{
        fontFamily: "Brandon",
        fontWeight: 800,
        fontSize: 15,
        p: "14px 98px",
        "&.Mui-disabled": {
          color: "#ffffff",
          backgroundColor: "#777473",
        },
        width: {
          xs: "100%",
          md: "auto",
        },
      }}
      disabled={disabled}
    >
      Submit
    </Button>
  );
};

export default SubmitButton;
