import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  wellnessStatusIds: Yup.array().required("Required").min(1),
  mindset: Yup.number().required("Required").typeError("Required"),
  physical: Yup.number().required("Required").typeError("Required"),
  emotional: Yup.number().required("Required").typeError("Required"),
  social: Yup.number().required("Required").typeError("Required"),
  financial: Yup.number().required("Required").typeError("Required"),
  age: Yup.number()
    .min(18)
    .max(100)
    .required("Required")
    .integer()
    .typeError("Required"),
  hasInsuranceId: Yup.number().required("Required").typeError("Required"),

  insuranceBenefitIds: Yup.array().when("hasInsuranceId", {
    is: (hasInsuranceId) => {
      return hasInsuranceId === 1;
    },
    then: Yup.array().test(
      " insuranceBenefitIds",
      "Required",
      function (values) {
        return values.length !== 0;
      }
    ),
    otherwise: Yup.array(),
  }),

  resourceIds: Yup.array().required("Required").min(1),
  priorDiagnosisIds: Yup.array().required("Required").min(1),
  otherPriorDiagnosis: Yup.string().when("priorDiagnosisIds", {
    is: (priorDiagnosisIds) => {
      const check = priorDiagnosisIds.some((id) =>
        [14].some((value) => id === value)
      );

      if (check) {
        return true;
      }
    },
    then: Yup.string().required("Required").typeError("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),
  heightFeet: Yup.string().required("Required").typeError("Required"),
  heightInches: Yup.string().required("Required").typeError("Required"),
  weight: Yup.string().required("Required").typeError("Required"),
  currentTreatmentIds: Yup.array().required("Required").min(1),
  ivfCycleId: Yup.number().required("Required").typeError("Required"),
  journeyDifficultyIds: Yup.array().required("Required").min(1),
  pregnancyAmount: Yup.string().required("Required").typeError("Required"),
  liveBirthAmount: Yup.string().required("Required").typeError("Required"),
  miscarriageAmount: Yup.string().required("Required").typeError("Required"),
});

export default validationSchema;
