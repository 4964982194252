import Chip from "src/components/common/Crm/Chip";
import Stack from "@mui/material/Stack";

const Tags = ({ tags }) => {
  if (!tags) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5}>
      {tags.map((tag) => (
        <Chip key={tag} label={tag.label} color={tag.hexCode} />
      ))}
    </Stack>
  );
};

export default Tags;
