import { useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Page from "src/components/common/Page";
import IntakeForm from "src/components/Forms/Intake";
import queryString from "query-string";
import ClientHeader from "src/components/common/ClientHeader";
import FolFooter from "src/components/Forms/common/FolFooter";

const Intake = () => {
  const pageRef = useRef(null);
  const location = useLocation();
  // TODO: handle legacy form links (update after 08/30/2023)

  const params = useParams();
  const query = queryString.parse(location.search);
  const clientId = params?.clientId || query?.clientId;
  const formId = params?.formId || query?.formId;

  return (
    <Page
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      title="Intake Form"
      ref={pageRef}
    >
      <ClientHeader />
      <IntakeForm clientId={clientId} formId={formId} />
      <FolFooter veevaCode={"US-RMMH-2300234"} />
    </Page>
  );
};

export default Intake;
