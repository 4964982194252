import { useState, useContext } from "react";
import { Button, Grid, TextField } from "@mui/material";
import PhoneInput from "src/components/Forms/common/PhoneInput";
import { UserContext } from "src/context/UserContext";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import AutoResizeInput from "src/components/common/AutoResizeInput";

const Composer = ({ handleGetMassTexts }) => {
  const { agent } = useContext(UserContext);
  const [message, setMessage] = useState("");
  const [batchSize, setBatchSize] = useState(5);
  const [testPhoneNumber, setTestPhoneNumber] = useState("");

  const handleChange = (event) => {
    event.persist();
    setMessage(event.target.value);
  };

  const handleCreateMassMessage = async () => {
    try {
      await axios.post("/api/messages/mass-text", {
        body: message,
        batchSize,
      });
      setMessage("");

      handleGetMassTexts(1);
    } catch (error) {
      toast.error(`Error creating mass text`);
    }
  };

  const handleTestMessage = async (message, phoneNumber) => {
    try {
      await axios.post("/api/messages/out", {
        body: message,
        phoneNumber,
        auth0Id: agent.auth0Id,
      });
    } catch (error) {
      toast.error(`Error sending test message`);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AutoResizeInput
          value={message}
          handleChange={handleChange}
          placeholder="Enter Mass Text"
          minRows={4}
          maxRows={10}
          sx={{
            backgroundColor: "background.dark",
          }}
        />
      </Grid>

      <Grid item container xs={12} spacing={1} justifyContent="flex-end">
        <Grid item xs={6}>
          <PhoneInput
            fullWidth
            variant="outlined"
            name="phoneNumber"
            placeholder="Phone"
            value={testPhoneNumber}
            onChange={(value) => setTestPhoneNumber(value)}
            sx={{
              "& fieldset": {
                border: (theme) => `1px solid ${theme.palette.divider}`,
                width: "100%",
              },
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            color="primary"
            variant="contained"
            sx={{
              height: "100%",
              backgroundColor: "yellow",
            }}
            fullWidth
            disabled={!testPhoneNumber || !message.length}
            onClick={() => handleTestMessage(message, testPhoneNumber)}
          >
            Send Test Message
          </Button>
        </Grid>
      </Grid>

      <Grid item container spacing={1} justifyContent="flex-end">
        <Grid xs={2} item>
          <TextField
            label="Batch Size"
            type="number"
            fullWidth
            value={batchSize}
            onChange={(e) => setBatchSize(e.target.value)}
          />
        </Grid>
        <Grid xs={3} item>
          <Button
            fullWidth
            color="primary"
            sx={{
              height: "100%",
            }}
            variant="contained"
            disabled={!message.length}
            onClick={() => handleCreateMassMessage()}
          >
            Create Mass Text
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Composer;
