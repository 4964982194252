import { Stack, Typography, Chip } from "@mui/material";
import React from "react";
import DisplayChips from "src/components/common/Crm/DisplayChips";
import WellbeingPoint from "../FivePoints";
import { TextInput } from "src/components/Forms/common";

const ClientJourneyItem = ({ item }) => {
  const {
    comment,
    journeyUpdateEvent,
    notAtReReasons,
    otherNotAtReReason,
    wellbeingPoint,
    otherJourneyUpdateEvent,
  } = item;

  return (
    // Comment
    <Stack spacing={1.5}>
      {Boolean(comment) && (
        <Stack>
          <Typography
            sx={{
              fontSize: 12,
            }}
            component="label"
            variant="h6"
          >
            Comment
          </Typography>
          <TextInput
            variant="filled"
            name="comment"
            readOnly
            multiline
            value={comment}
            sx={{
              p: 0,
              "& textarea": {
                p: 0,
              },
            }}
          />
        </Stack>
      )}
      {/* Fertility Treatment - Display Chip */}
      {Boolean(journeyUpdateEvent) && (
        <Stack>
          <Typography
            sx={{
              fontSize: 12,
            }}
            component="label"
            variant="h6"
          >
            Event
          </Typography>
          <Chip
            sx={{
              width: "fit-content",
            }}
            id={journeyUpdateEvent.id}
            label={journeyUpdateEvent.label}
            color="primary"
          />
        </Stack>
      )}

      {Boolean(otherJourneyUpdateEvent) && (
        <Stack>
          <Typography
            sx={{
              fontSize: 12,
            }}
            component="label"
            variant="h6"
          >
            Other Event Update
          </Typography>
          <Typography variant="body1">{otherJourneyUpdateEvent}</Typography>
        </Stack>
      )}

      {Boolean(notAtReReasons?.length) && (
        <DisplayChips label="Not at RE Reasons" values={notAtReReasons} />
      )}
      {Boolean(otherNotAtReReason) && (
        <Stack>
          <Typography
            component="label"
            sx={{
              fontSize: 12,
            }}
            variant="h6"
          >
            Other Not At RE Reason
          </Typography>
          <Typography variant="body1">{otherNotAtReReason}</Typography>
        </Stack>
      )}

      {Boolean(wellbeingPoint) && <WellbeingPoint item={wellbeingPoint} />}
    </Stack>
  );
};

export default ClientJourneyItem;
