import { useState, useEffect, useContext } from "react";
import axios from "src/utils/axios";
import { OptionContext } from "src/context/OptionContext";
import SingleSelectDropdown from "src/components/common/SingleSelect";
import { toast } from "react-toastify";

const JourneyStageSelect = ({ clientId, value: initialValue }) => {
  const { journeyStageOptions } = useContext(OptionContext);
  const [journeyStageId, setJourneyStageId] = useState("");

  const handleJourneyStageChange = async (journeyStageId) => {
    try {
      await axios.put(`/api/clients/${clientId}`, {
        journeyStageId,
      });
      setJourneyStageId(journeyStageId);
    } catch (error) {
      console.error(error.message);
      toast.error(`Error updating Journey Stage`);
    }
  };

  useEffect(() => {
    if (!initialValue) return;
    const initialValueId = initialValue.id;
    setJourneyStageId(initialValueId);
  }, [initialValue]);

  return (
    <SingleSelectDropdown
      id="journeyStage"
      label="Journey Stage"
      value={journeyStageId}
      options={journeyStageOptions}
      sx={{
        height: "100%",
      }}
      onChange={handleJourneyStageChange}
    />
  );
};

export default JourneyStageSelect;
