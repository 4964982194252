import { useState, useEffect, useContext } from "react";
import { Card, CardContent, Stack } from "@mui/material";
import MultiSelectDropdown from "src/components/common/MultiSelect";
import { OptionContext } from "src/context/OptionContext";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";

const handleAddOption = async ({ optionId, optionName, clientId }) => {
  const values = {
    optionId,
    optionName,
  };
  try {
    const { data: currentOptions } = await axios.post(
      `/api/clients/${clientId}/crm-options`,
      values
    );
    return currentOptions;
  } catch (error) {
    toast.error(`Error adding ${optionName}`);
    console.error(error.message);
  }
};

const handleDeleteOption = async ({ optionId, optionName, clientId }) => {
  const values = {
    optionId,
    optionName,
  };
  try {
    const { data: currentOptions } = await axios.delete(
      `/api/clients/${clientId}/crm-options`,
      {
        data: values,
      }
    );
    return currentOptions;
  } catch (error) {
    toast.error(`Error deleting ${optionName}`);
    console.error(error.message);
  }
};

const TopicSelect = ({
  clientId,
  topics: initialTopics,
  subtopics: initialSubtopics,
}) => {
  const { topicOptions, subtopicOptions } = useContext(OptionContext);
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);

  const socketHandleUpdateTopicSelect = ({ optionName, results }) => {
    switch (optionName) {
      case "topic":
        setTopics(results);
        break;
      case "subtopic":
        setSubtopics(results);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (initialTopics) {
      setTopics(initialTopics);
    }
    if (initialSubtopics) {
      setSubtopics(initialSubtopics);
    }
  }, [initialTopics, initialSubtopics]);

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_TOPIC_SELECT,
        handler: socketHandleUpdateTopicSelect,
      },
    ],
    clientId
  );

  return (
    <Card elevation={0} square variant="outlined">
      <CardContent>
        <Stack direction={{ xs: "column", lg: "row" }} spacing={1} useFlexGap>
          <MultiSelectDropdown
            id="topic"
            label="Topics"
            options={topicOptions}
            values={topics}
            filterSelectedOptions
            addValue={(optionId) => {
              handleAddOption({ optionId, optionName: "topic", clientId });
            }}
            removeValue={(optionId) => {
              handleDeleteOption({ optionId, optionName: "topic", clientId });
            }}
          />
          <MultiSelectDropdown
            id="subtopic"
            options={subtopicOptions}
            values={subtopics}
            label="Subtopics"
            name="subtopic"
            filterSelectedOptions
            addValue={(optionId) => {
              handleAddOption({ optionId, optionName: "subtopic", clientId });
            }}
            removeValue={(optionId) => {
              handleDeleteOption({
                optionId,
                optionName: "subtopic",
                clientId,
              });
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TopicSelect;
