import propTypes from "prop-types";
import { useState } from "react";
import {
  Chip,
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  Paper,
  Typography,
} from "@mui/material";
import getFontColorFromBackground from "src/utils/getFontColorFromBackground";
import ToolTip from "src/components/common/ToolTip";

const CustomPopper = ({ tags, handleRemoveTag, anchorEl }) => {
  return (
    <Popper
      // Setting zIndex to 2 to make sure the popper is above the FilterMenu dropdown
      sx={{ zIndex: 2 }}
      open={true}
      anchorEl={anchorEl}
      placement="bottom-end"
    >
      <Paper elevation={3}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: "300px",
            flexWrap: "wrap",
            padding: (theme) => theme.spacing(1),
          }}
          useFlexGap
        >
          {tags.map((tag) => {
            return (
              <Chip
                key={tag.id}
                label={tag.label}
                id={tag.id}
                sx={{
                  backgroundColor: tag?.hexCode || "babyBlue",
                  color: (theme) =>
                    getFontColorFromBackground(
                      tag?.hexCode || theme.palette.babyBlue
                    ),
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  const tagToBeRemoved = tags.filter((t) => t.id === tag.id)[0];
                  handleRemoveTag(tagToBeRemoved);
                }}
              />
            );
          })}
        </Stack>
      </Paper>
    </Popper>
  );
};

/**
 * TagPopper component
 * @param {Array} tags - array of tags
 * @param {Function} handleRemoveTag - function to remove a tag
 * @param {HTMLElement} anchorEl - parent anchor element (optional)
 * @param {Boolean} isTagPopperOpen - boolean to check if the tag popper is open
 * @param {Function} setIsTagPopperOpen - function to set the tag popper open state
 */
const TagPopper = ({
  tags,
  handleRemoveTag,
  anchorEl: initialAnchorEl,
  isTagPopperOpen,
  setIsTagPopperOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSetOpen = (e) => {
    setAnchorEl(initialAnchorEl || e.currentTarget);
    setIsTagPopperOpen(!isTagPopperOpen);
  };

  const handleSetClose = (e) => {
    e.stopPropagation();
    setIsTagPopperOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleSetClose}>
        <IconButton
          sx={{
            width: (theme) => theme.spacing(4),
            height: (theme) => theme.spacing(4),
            padding: 0,
          }}
          onClick={handleSetOpen}
        >
          <ToolTip
            title={tags.map((tag) => tag.label).join(", ")}
            placement="top-start"
          >
            <Typography
              sx={{ cursor: "pointer" }}
              variant="h5"
              color="text.primary"
            >{`+${tags.length}`}</Typography>
          </ToolTip>
        </IconButton>
      </ClickAwayListener>
      {isTagPopperOpen && (
        <CustomPopper
          tags={tags}
          handleRemoveTag={handleRemoveTag}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};

export default TagPopper;

TagPopper.propTypes = {
  tags: propTypes.array.isRequired,
  handleRemoveTag: propTypes.func.isRequired,
  anchorEl: propTypes.object,
  isTagPopperOpen: propTypes.bool.isRequired,
  setIsTagPopperOpen: propTypes.func.isRequired,
};
