import { useEffect, useState } from "react";
import { Divider, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { toast } from "react-toastify";
import VirtualSessionsForm from "./VirtualSessionsForm";
import VirtualSession from "./VirtualSession";
import BoxContainer from "src/components/common/Crm/BoxContainer";
import axios from "src/utils/axios";
import { STATIC_OPACITY, LOADING_OPACITY } from "src/utils/constants.js";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";

const getVirtualSessions = async (clientId) => {
  try {
    const { data: virtualSessionsData } = await axios.get(
      `/api/clients/${clientId}/virtual-sessions`
    );

    return virtualSessionsData;
  } catch (error) {
    throw error;
  }
};

const VirtualSessions = ({ clientId }) => {
  const [virtualSessions, setVirtualSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [opacity, setOpacity] = useState(STATIC_OPACITY);

  const handleCopyLink = async (linkType, link) => {
    try {
      await navigator.clipboard.writeText(link);
      toast.success(`${linkType} was successfully copied to your clipboard!`);
    } catch (error) {
      console.log("Error copying link =>", error);
      toast.error(`Error copying ${linkType} to your clipboard`);
    }
  };

  const handleGetVirtualSessions = async () => {
    try {
      const newVirtualSessions = await getVirtualSessions(clientId);
      newVirtualSessions.sort((a, b) => a.id - b.id);

      setVirtualSessions(newVirtualSessions);
    } catch (error) {
      setHasError(true);
      setVirtualSessions([]);
      console.log("Error fetching virtual sessions =>", error);
    }
  };

  const handleCreateVirtualSession = async (agentId, resetForm) => {
    try {
      setOpacity(LOADING_OPACITY);
      await axios.post(`/api/clients/${clientId}/virtual-sessions`, {
        agentId: agentId,
      });

      await handleGetVirtualSessions();

      await resetForm();
    } catch (error) {
      toast.error("Error creating virtual session");
      console.log("Error creating virtual session =>", error);
    } finally {
      setOpacity(STATIC_OPACITY);
    }
  };

  const handleUpdateVirtualSession = async (
    virtualSessionId,
    statusType,
    isChecked
  ) => {
    try {
      const payload = { [statusType]: isChecked };

      await axios.put(
        `/api/clients/${clientId}/virtual-sessions/${virtualSessionId}`,
        payload
      );

      await handleGetVirtualSessions();
    } catch (error) {
      toast.error("Unable to update Virtual Session. please try again");
      console.log("Error updating virtual session =>", error);
    }
  };

  const handleDeleteVirtualSession = async (virtualSessionId, surveyId) => {
    try {
      setOpacity(LOADING_OPACITY);

      // Sending surveyId as a query param to delete the survey associated with the virtual session if the survey hasn't been completed
      const params = { surveyId };

      await axios.delete(
        `/api/clients/${clientId}/virtual-sessions/${virtualSessionId}`,
        {
          params,
        }
      );

      await handleGetVirtualSessions();
    } catch (error) {
      toast.error("Unable to remove virtual session, please try again");
      console.log("Error removing virtual session =>", error);
    } finally {
      setOpacity(STATIC_OPACITY);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const newVirtualSessions = await getVirtualSessions(clientId);
        newVirtualSessions.sort((a, b) => a.id - b.id);
        setVirtualSessions(newVirtualSessions);
      } catch (error) {
        setHasError(true);
        setVirtualSessions([]);
        console.log("Error fetching virtual sessions =>", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [clientId]);

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_VIRTUAL_SESSIONS,
        handler: handleGetVirtualSessions,
      },
    ],
    clientId
  );

  return (
    <Card elevation={0} square variant="outlined">
      <CardHeader title={"Virtual Sessions"} />
      <Divider />
      <CardContent>
        <BoxContainer
          title="Virtual Sessions"
          isLoading={isLoading}
          hasError={hasError}
          errorMessage="Error loading Virtual Sessions. Please try again"
          opacity={opacity}
          hasChildren
        >
          <Stack useFlexGap spacing={2}>
            {/* VIRTUAL SESSIONS ITEM */}
            {virtualSessions.length > 0 && (
              <Stack divider={<Divider />}>
                {virtualSessions.map((session) => (
                  <VirtualSession
                    key={session.id}
                    session={session}
                    handleCopyLink={handleCopyLink}
                    handleUpdateVirtualSession={handleUpdateVirtualSession}
                    handleDeleteVirtualSession={handleDeleteVirtualSession}
                    clientId={clientId}
                  />
                ))}
              </Stack>
            )}

            {/* VIRTUAL SESSIONS FORM */}
            <VirtualSessionsForm
              virtualSessions={virtualSessions}
              handleCreateVirtualSession={handleCreateVirtualSession}
            />
          </Stack>
        </BoxContainer>
      </CardContent>
    </Card>
  );
};

export default VirtualSessions;
