import { Stack, Typography, FormHelperText } from "@mui/material";
import Chip from "src/components/common/Crm/Chip";
import DisplayText from "src/components/common/Crm/DisplayText";
import Tooltip from "src/components/common/ToolTip";

const DisplayChips = ({ values, label, otherOption }) => {
  return (
    <Stack spacing={1} sx={{ mt: 1.2 }}>
      <Typography
        component="label"
        variant="h6"
        sx={{
          fontSize: 12,
        }}
      >
        {label}
      </Typography>

      <Stack direction="row">
        {values?.length ? (
          values.map((value) => <Chip key={value.id} label={value.label} />)
        ) : (
          <DisplayText secondary={"N/A"} />
        )}
      </Stack>
      {Boolean(otherOption?.length) && (
        <>
          <Tooltip
            arrow
            title={otherOption.length > 20 ? otherOption : ""}
            placement="top-start"
          >
            <FormHelperText
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                mt: 0,
                lineHeight: "100%",
              }}
            >
              {otherOption}
            </FormHelperText>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default DisplayChips;
