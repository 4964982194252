import { ListItemText, Typography } from "@mui/material";
import Tooltip from "src/components/common/ToolTip";

const setValueOrNa = (value) => {
  return value ? value : "-";
};

const DisplayText = ({ primary, secondary }) => {
  return (
    <ListItemText
      primary={
        <Typography fontWeight={500} variant="caption">
          {primary}
        </Typography>
      }
      secondary={
        <Tooltip
          arrow
          title={secondary === "N/A" ? "" : secondary}
          placement="top-start"
        >
          <Typography
            noWrap
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "noWrap",
            }}
          >
            {setValueOrNa(secondary)}
          </Typography>
        </Tooltip>
      }
      disableTypography
    />
  );
};

export default DisplayText;
