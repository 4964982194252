import { Box, Typography, Button, Link, Stack, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
const useStyles = makeStyles((theme) => ({
  heroImage: {
    position: "relative",
    top: 40,
    right: 0,
    [theme.breakpoints.up("xs")]: {
      width: "320px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "568px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const ThankYou = () => {
  const classes = useStyles();

  const { isMobile } = useDeviceBreakpoint();

  return (
    <>
      <Container
        maxWidth="false"
        sx={{
          backgroundColor: "primary.main",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="md" sx={{ padding: "0 !important" }}>
          <Stack
            sx={{
              pt: 16,
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: 0, md: 10 },
            }}
          >
            {/* TOP LEFT */}
            <Stack
              sx={{
                alignItems: { xs: "center", md: "flex-start" },
                maxWidth: "300px",
              }}
            >
              <Typography
                component="h1"
                variant="brandonH1"
                color="white"
                sx={{
                  fontWeight: 400,
                  position: "relative",
                  fontSize: "42px",
                  mb: 3,
                  zIndex: 1,
                  "&:after": {
                    content: "''",
                    position: "absolute",
                    left: 110,
                    bottom: 0,
                    transform: "translateX(-50%) skew(-20deg)",
                    width: 241,
                    height: 27,
                    backgroundColor: "primary.dark",
                    zIndex: "-1",
                  },
                }}
              >
                You’re all set!
              </Typography>
              <Typography
                variant="brandonBody1"
                sx={{
                  // maxWidth: 300,
                  color: "white",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                We can't wait to start texting with you. One of our fertility
                coaches will reach out shortly so be sure to check your phone!
              </Typography>
            </Stack>
            {/* TOP RIGHT */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className={classes.heroImage}
                src="/static/images/people.png"
                alt="logo"
              />
            </Box>
          </Stack>
        </Container>
      </Container>
      {/* BOTTOM */}
      <Container
        maxWidth="false"
        sx={{
          backgroundColor: "white",
          height: "100%",
          display: "flex",
          alignItems: "center",
          py: 12,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            padding: "0 !important",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              maxWidth: { xs: 320, sm: 568, md: "100%" },
              pt: 6.5,
              pr: { xs: 0, md: 10 },
              backgroundColor: "skyBlue",
              width: "100%",
              gap: 3,
            }}
          >
            <Box
              sx={{
                alignSelf: { xs: "start", md: "flex-end" },
                width: { xs: "316px", sm: "510px" },
                height: { xs: "111px", sm: "214px" },
                overflow: "hidden",
                order: { xs: 2, md: 0 },
              }}
            >
              {isMobile ? (
                <img
                  src="/static/images/FertilityOutLoudMobile.svg"
                  alt="FertilityOutLoud"
                />
              ) : (
                <img
                  width="607px"
                  src="/static/images/FertilityOutLoud.svg"
                  alt="FertilityOutLoud"
                />
              )}
            </Box>
            <Stack
              sx={{
                flex: 1,
                px: { xs: 4, sm: 10, md: 0 },
                alignItems: { xs: "center", md: "flex-start" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                variant="brandonTitle"
                sx={{ fontWeight: 400, fontSize: "24px" }}
              >
                WHILE YOU WAIT...
              </Typography>
              <Typography variant="brandonBody1">
                Check out <strong>Fertility Out Loud</strong> to find more
                resources and support for your fertility journey.
              </Typography>
              <Link
                href="https://www.fertilityoutloud.com/"
                target="_blank"
                rel="noreferrer"
                underline="none"
              >
                <Button
                  variant="contained"
                  sx={{
                    my: 3,
                    width: 176,
                    backgroundColor: "#000000",
                    padding: "14px 0",
                    color: "#ffffff",
                    borderRadius: "0px",
                    fontSize: 15,
                    lineHeight: "20px",
                    fontFamily: "Brandon",
                    fontWeight: "Bold",
                  }}
                >
                  LET’S GO!
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Container>
      </Container>
    </>
  );
};

export default ThankYou;
