import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Checkbox,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { Copy as CopyIcon } from "react-feather";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";

const Forms = ({ forms: initialForms, clientId }) => {
  const [forms, setForms] = useState([]);

  const handleSetForms = (updatedForm) => {
    setForms((prevForms) => {
      return prevForms.map((prevForm) => {
        if (prevForm.id === updatedForm.id) {
          // Check if the form has already been updated
          if (JSON.stringify(prevForm) === JSON.stringify(updatedForm)) {
            return prevForm; // Update has has already been applied
          }
          return updatedForm;
        }
        return prevForm;
      });
    });
  };

  const handleUpdateForm = async (name, isChecked, formId, clientId) => {
    try {
      const { data: updatedForm } = await axios.put(
        `/api/clients/${clientId}/forms/${formId}`,
        {
          [name]: isChecked,
        }
      );
      handleSetForms(updatedForm);
    } catch (error) {
      toast.error(`Error updating form`);
    }
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    if (initialForms) {
      setForms(initialForms);
    }
  }, [initialForms]);

  // Sort forms
  if (forms.length) {
    forms.sort((a, b) => a.id - b.id);
  }

  const socketHandleUpdateForm = (updatedForm) => {
    handleSetForms(updatedForm);
  };

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_CLIENT_FORMS,
        handler: socketHandleUpdateForm,
      },
    ],
    clientId
  );

  return (
    <Card>
      <CardHeader title="Forms" />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            {/* Spacer */}
          </Grid>

          <Grid item xs={2}>
            <Typography variant="h6">Sent #1</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Sent #2</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="h6">Received</Typography>
          </Grid>
        </Grid>

        {forms.map((form) => {
          return (
            <Grid container key={form.id}>
              <Grid item xs={6} container alignContent="center">
                <Typography variant="h6" color="primary">
                  {form.name}

                  <IconButton
                    color="primary"
                    size="small"
                    disabled={form.isReceived}
                    onClick={() => handleCopy(form.shortLink || form.link)}
                  >
                    <SvgIcon fontSize="small">
                      <CopyIcon />
                    </SvgIcon>
                  </IconButton>
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography variant="h6">
                  <Checkbox
                    name="isSent1"
                    onChange={(e, isChecked) =>
                      handleUpdateForm(
                        e.target.name,
                        isChecked,
                        form.id,
                        clientId
                      )
                    }
                    color="primary"
                    checked={Boolean(form.isSent1)}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">
                  <Checkbox
                    name="isSent2"
                    onChange={(e, isChecked) =>
                      handleUpdateForm(
                        e.target.name,
                        isChecked,
                        form.id,
                        clientId
                      )
                    }
                    color="primary"
                    checked={Boolean(form.isSent2)}
                  />
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography variant="h6">
                  <Checkbox
                    color="success"
                    name="isReceived"
                    checked={Boolean(form.isReceived)}
                  />
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default Forms;
