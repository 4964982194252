import PropTypes from "prop-types";
import React, { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "src/context/UserContext";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";
import PerfectScrollbar from "react-perfect-scrollbar";

import axios from "src/utils/axios";
import {
  Card,
  Box,
  CardHeader,
  Divider,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from "@mui/material";
import { Flag as FlagIcon } from "@mui/icons-material";
import {
  Timeline as MuiTimeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineItem as MuiTimelineItem,
} from "@mui/lab";
import { toast } from "react-toastify";

import { STATIC_OPACITY, LOADING_OPACITY } from "src/utils/constants";
import BoxContainer from "src/components/common/Crm/BoxContainer";
import Update from "src/components/Client/ClientJourney/Update";
import TimelineItemContainer from "./TimelineItemContainer";
import Filter from "./Filter";
import ClientJourneyFormModal from "src/components/Client/ClientJourney/Update/Modal";

const getClientJourneyItems = async (clientId) => {
  try {
    const { data: clientJourneyItems } = await axios.get(
      `/api/clients/${clientId}/journey`
    );

    return clientJourneyItems;
  } catch (error) {
    throw new Error("Unable to fetch client journey items");
  }
};

const ClientJourney = ({ clientId, displayName }) => {
  const { agent, isAgentAdmin } = useContext(UserContext);
  const [clientJourneyItems, setClientJourneyItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [editId, setEditId] = useState(null);
  const [opacity, setOpacity] = useState(STATIC_OPACITY);
  const scrollBarRef = useRef(null);
  const editItemTopRef = useRef(null);

  const handleFilter = async (filters) => {
    const hasFilters = Object.keys(filters).some((key) => filters[key]);

    if (!hasFilters) {
      const newJourneyItems = await getClientJourneyItems(clientId);
      setClientJourneyItems(newJourneyItems);
      return;
    }

    // If there are Filters, filter the client journey items based on the filters:
    const filteredClientJourneyItems = clientJourneyItems.filter((item) => {
      const hasEvent = Boolean(item.journeyUpdateEvent);
      const hasComment = Boolean(item.comment);
      const hasWellbeingPoint = Boolean(item.wellbeingPoint);

      return (
        (filters.hasEvent && hasEvent) ||
        (filters.hasComment && hasComment) ||
        (filters.hasWellbeingPoint && hasWellbeingPoint)
      );
    });

    setClientJourneyItems(filteredClientJourneyItems);
  };

  const handleGetClientJourneyItems = async () => {
    try {
      const newClientJourneyItems = await getClientJourneyItems(clientId);

      setClientJourneyItems(newClientJourneyItems);
    } catch (error) {
      setHasError(true);
    }
  };

  const handleCreateNewClientJourneyItem = async (payload, resetForm) => {
    try {
      setOpacity(LOADING_OPACITY);

      await axios.post(`/api/clients/${clientId}/journey`, {
        ...payload,
        agentId: agent.id,
      });

      await handleGetClientJourneyItems();

      resetForm();
    } catch (error) {
      setHasError(true);
      toast.error("Error creating a new timeline item");
      console.log("Error creating new timeline items =>", error);
    } finally {
      setOpacity(STATIC_OPACITY);
    }
  };

  const handleEditClientJourneyItem = async ({ itemId, ...payload }) => {
    try {
      setOpacity(LOADING_OPACITY);
      const clientUpdateJourneyid = itemId;

      await axios.put(
        `/api/clients/${clientId}/journey/${clientUpdateJourneyid}`,
        {
          ...payload,
        }
      );
      await handleGetClientJourneyItems();

      setEditId(null);
    } catch (error) {
      toast.error("Unable to update client journey item, please try again");
      console.log("Error updating client journey item =>", error);
    } finally {
      // Scroll to the edited item
      if (scrollBarRef.current && editItemTopRef.current) {
        scrollBarRef.current.scrollTop = editItemTopRef.current + 300;
        editItemTopRef.current = null;
      }

      setOpacity(STATIC_OPACITY);
    }
  };

  const handleDeleteClientJourneyItem = async ({ itemId }) => {
    try {
      setOpacity(LOADING_OPACITY);
      const clientUpdateJourneyId = itemId;
      await axios.delete(
        `/api/clients/${clientId}/journey/${clientUpdateJourneyId}`
      );
      await handleGetClientJourneyItems();
    } catch (error) {
      toast.error("Unable to remove client journey item, please try again");
      console.log("Error removing client journey item =>", error);
    } finally {
      setOpacity(STATIC_OPACITY);
    }
  };

  const handleSetEditId = (itemId) => {
    // Get the location of the edit item
    if (itemId) {
      const editItem = document.getElementById(`journey-item-${itemId}`);
      const editItemTop = editItem.getBoundingClientRect().top;
      // Get the location of the edit item
      editItemTopRef.current = editItemTop;
    }
    setEditId(itemId);
  };

  const hasEditAction = (
    isFormUpdate,
    isAgentAdmin,
    isAgentUpdate,
    isWellbeingUpdate,
    isJoinedDate
  ) => {
    let isEditable = false;
    // Allows only Admins to update form updates
    if (isFormUpdate && isAgentAdmin) {
      isEditable = true;
    }
    // Allows all agents to update agent updates
    if (isAgentUpdate) {
      isEditable = true;
    }
    // Wellbeing Points or Joined Dates are not editable
    if (isWellbeingUpdate || isJoinedDate) {
      isEditable = false;
    }
    return isEditable;
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setHasError(false);

        await handleGetClientJourneyItems();
      } catch (error) {
        setHasError(true);
        setClientJourneyItems([]);
        console.log("Error fetching client journey items =>", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [clientId]);

  useSocketEvents([
    {
      eventName: SOCKET_EVENTS.UPDATE_CLIENT_JOURNEY,
      handler: handleGetClientJourneyItems,
    },
  ]);

  const journeyItems = clientJourneyItems;

  return (
    <Card elevation={0} square variant="outlined">
      <CardHeader
        title="Client Journey"
        action={
          <>
            <ClientJourneyFormModal
              onSubmit={handleCreateNewClientJourneyItem}
              clientId={clientId}
            />
            <Filter handleFilter={handleFilter} />
          </>
        }
      />
      <Divider />

      <Stack spacing={2} p={2}>
        <BoxContainer
          title="Client Timeline"
          isLoading={isLoading}
          hasError={hasError}
          errorMessage={"Unable to fetch Jouney"}
          opacity={opacity}
          hasChildren={Boolean(journeyItems.length)}
        >
          {!isLoading && Boolean(journeyItems.length) ? (
            <PerfectScrollbar
              containerRef={(el) => {
                scrollBarRef.current = el;
              }}
              options={{ suppressScrollX: true }}
            >
              <Box maxHeight={"75vh"}>
                <MuiTimeline>
                  {journeyItems.map((item, index) => {
                    const { type: itemType } = item;

                    const isUpdate = itemType === "update";
                    const isAgentUpdate = isUpdate && Boolean(item.agent);
                    const isFormUpdate = isUpdate && !isAgentUpdate;
                    const isWellbeingUpdate = Boolean(item.wellbeingPoint);
                    const isJoinedDate = itemType === "joinedDate";

                    const isEditable = hasEditAction(
                      isFormUpdate,
                      isAgentAdmin,
                      isAgentUpdate,
                      isWellbeingUpdate,
                      isJoinedDate
                    );

                    return (
                      <MuiTimelineItem
                        position="right"
                        key={index}
                        sx={{
                          "&::before": {
                            flex: 0,
                            padding: 0,
                          },
                        }}
                        style={{ opacity }}
                      >
                        <TimelineSeparator
                          sx={{
                            "& .MuiTimelineDot-root": {
                              padding: 0,
                              borderWidth: 0,
                              width: "40px",
                              height: "40px",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          }}
                        >
                          {isUpdate && (
                            <TimelineDot color="primary">
                              <Tooltip
                                placement="top"
                                arrow={true}
                                title={`Created by ${
                                  item.agent?.name || displayName
                                }`}
                              >
                                <Avatar src={item.agent?.avatarLink}>
                                  {item.agent?.firstName[0] +
                                    item.agent?.lastName[0] || displayName[0]}
                                </Avatar>
                              </Tooltip>
                            </TimelineDot>
                          )}

                          {isJoinedDate && (
                            <TimelineDot color="primary">
                              <FlagIcon />
                            </TimelineDot>
                          )}

                          {index + 1 < journeyItems.length ? (
                            <TimelineConnector />
                          ) : null}
                        </TimelineSeparator>
                        <TimelineContent>
                          <TimelineItemContainer
                            hasEditAction={isEditable}
                            hasDeleteAction={Boolean(item.agent) && isUpdate}
                            createdAt={item.createdAt}
                            index={index}
                            currentEditId={editId}
                            itemId={item.id}
                            itemType={itemType}
                            handleDeleteItem={handleDeleteClientJourneyItem}
                            handleSetEditId={handleSetEditId}
                          >
                            {isUpdate && (
                              <Update
                                item={item}
                                itemType={itemType}
                                editId={editId}
                                index={index}
                                onEdit={(item) =>
                                  handleEditClientJourneyItem(item)
                                }
                                onCancel={() => handleSetEditId(null)}
                                clientId={clientId}
                              />
                            )}

                            {isJoinedDate && (
                              <Typography
                                sx={{ width: "100%" }}
                                variant="body1"
                              >
                                Joined Fertility Outreach
                              </Typography>
                            )}
                          </TimelineItemContainer>

                          {index + 1 < journeyItems.length && <Divider />}
                        </TimelineContent>
                      </MuiTimelineItem>
                    );
                  })}
                </MuiTimeline>
              </Box>
            </PerfectScrollbar>
          ) : null}
        </BoxContainer>
      </Stack>
    </Card>
  );
};

ClientJourney.propTypes = {
  clientId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};

export default ClientJourney;
