import { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import validationSchema from "./validationSchema";
import axios from "src/utils/axios";
import getIdFromLabel from "src/utils/getIdFromLabel";
import { OptionContext } from "src/context/OptionContext";
import {
  ColumnSelect,
  SingleRadioSelect,
  HiddenLogicComponent,
  TextInput,
  SubmitButton,
  RobynDisclaimer,
  ContentWrapper,
  Heading,
  FormWrapper,
  ErrorBanner,
  MultiSelect,
  FivePointList,
} from "src/components/Forms/common";

const initialFormOptions = {
  reCheck: [
    {
      id: 1,
      label: "I was seeing a fertility specialist and I'm still seeing them",
    },
    {
      id: 2,
      label: "I wasn't seeing a fertility specialist, but I am seeing one now",
    },
    {
      id: 3,
      label:
        "I was seeing a fertility specialist, but am no longer seeing them",
    },
    { id: 4, label: "I haven't seen a fertility specialist yet" },
  ],
};

const initialValues = {
  mindset: null,
  physical: null,
  emotional: null,
  social: null,
  financial: null,
  isSeeingReId: null,
  journeyUpdateEventId: null,
  otherJourneyUpdateEvent: "",
  notAtReReasonIds: [],
  otherNotAtReReason: "",
  isSeeingRe: null,
};

const getFormOptions = async () => {
  try {
    const { data: formOptions } = await axios.get("/api/crm/form-options", {
      params: {
        formName: "follow-up",
      },
    });
    return formOptions;
  } catch (error) {
    console.log("Error at fetching form options =>", error);
    throw error;
  }
};

const getClient = async (clientId) => {
  try {
    const { data: client } = await axios.get("/api/clients/public", {
      params: {
        clientId,
      },
    });
    return client;
  } catch (error) {
    throw error;
  }
};

const FollowUpForm = ({ clientId, formId }) => {
  const [formStatus, setFormStatus] = useState("IS_LOADING");
  const [client, setClient] = useState({});
  const [formOptions, setFormOptions] = useState({});
  const { journeyUpdateEventOptions } = useContext(OptionContext);

  const handleSubmit = async (values) => {
    setFormStatus("IS_LOADING");
    try {
      const journeyUpdateEvents = [];

      const wellbeingPoint = {
        mindset: parseInt(values.mindset),
        physical: parseInt(values.physical),
        emotional: parseInt(values.emotional),
        social: parseInt(values.social),
        financial: parseInt(values.financial),
      };

      const notAtReId = getIdFromLabel({
        options: journeyUpdateEventOptions,
        label: "Not at RE",
      });

      const atReId = getIdFromLabel({
        options: journeyUpdateEventOptions,
        label: "At RE",
      });

      const pregnantId = getIdFromLabel({
        options: journeyUpdateEventOptions,
        label: "Pregnant",
      });

      // If isSeeingRe is false add Not at RE event
      if (values.isSeeingRe) {
        journeyUpdateEvents.push({
          id: atReId,
        });

        journeyUpdateEvents.push({
          id: values.journeyUpdateEventId,
          otherJourneyUpdateEvent: values.otherJourneyUpdateEvent,
          wellbeingPoint,
        });
      } else {
        journeyUpdateEvents.push({
          id: notAtReId,
          notAtReReasonIds: values.notAtReReasonIds,
          otherNotAtReReason: values.otherNotAtReReason,
          wellbeingPoint,
        });

        if (
          values.notAtReReasonIds.includes(
            getIdFromLabel({
              label: "Pregnancy, transferred care to OBGYN",
              options: formOptions.notAtReReason,
            })
          )
        ) {
          journeyUpdateEvents.push({
            id: pregnantId,
          });
        }
      }

      const formValues = {
        ...values,
        displayName: client?.displayName,
        journeyUpdateEvents,
      };

      await axios.put(
        `/api/clients/${clientId}/follow-up/${formId}`,
        formValues
      );

      setFormStatus("SUCCESS");
    } catch (error) {
      console.error(error);

      if (error.response.status === 409) {
        setFormStatus("FORM_ALREADY_SUBMITTED");
        return;
      }
      setFormStatus("SUBMIT_ERROR");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // IF MISSING QUERY PARAMETERS SHOW LOAD ERROR
        if (!clientId || !formId) {
          setClient(null);
          setFormStatus("LOAD_ERROR");
          return;
        }

        const newFormOptions = await getFormOptions();

        const currentFormOptions = {
          ...initialFormOptions,

          ...newFormOptions,
        };

        setFormOptions(currentFormOptions);

        const client = await getClient(clientId);
        // IF CLIENT DOES NOT EXIST SHOW SIGN UP FORM
        if (!client) {
          setFormStatus("SHOW_SIGN_UP");
          return;
        }

        setClient(client);
        setFormStatus("SHOW_FORM");
      } catch (error) {
        console.log(error);
        setFormStatus("LOAD_ERROR");
      }
    })();
  }, [clientId, formId]);

  return (
    <ContentWrapper>
      <FormWrapper formName="Follow Up Form" formStatus={formStatus}>
        {/* TODO: Prevent FormWrapper children from rendering without the following conditional wrapper below */}
        {Boolean(formStatus === "SHOW_FORM") && (
          <>
            <Heading
              title={`Welcome ${client.firstName || ""}!`}
              subtitle=" We're just checking in! This helps ensure that our fertility
                coaches are continuing to provide the best, personalized support
                just for you."
            />
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                setFieldValue,
                errors,
                handleSubmit,
                isValid,
                setFieldError,
              }) => {
                const setField = (fieldName, value) => {
                  setFieldValue(fieldName, value);
                  setFieldError(fieldName, undefined);
                };

                const clearFields = (fieldNames) => {
                  fieldNames.forEach((fieldName) => {
                    setFieldValue(fieldName, initialValues[fieldName]);
                    setFieldError(fieldName, undefined);
                  });
                };

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ gap: 4.25 }}
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <ColumnSelect
                          errors={errors}
                          onChange={(name, value) => {
                            setField(name, value);
                          }}
                          title="Please rate how you feel on a scale of 1-5 in terms of each of the following 5 points of wellbeing:"
                          subTitle={<FivePointList />}
                          options={["1", "2", "3", "4", "5"]}
                          rows={[
                            {
                              name: "mindset",
                              label: "Mindset",
                            },
                            {
                              name: "physical",
                              label: "Physical Self",
                            },
                            {
                              name: "emotional",
                              label: "Emotional Self",
                            },
                            {
                              name: "social",
                              label: "Social Self",
                            },
                            {
                              name: "financial",
                              label: "Financial Self",
                            },
                          ]}
                        />
                      </Grid>
                      {/* IS SEEING RE? */}
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="Are you currently seeing a Fertility Specialist (Reproductive Endocrinologist or RE)?"
                          value={values.isSeeingReId}
                          onChange={(value) => {
                            const isSeeingRe = value === 1 ? true : false;

                            setField("isSeeingRe", isSeeingRe);
                            setField("isSeeingReId", value);
                          }}
                          options={[
                            {
                              id: 1,
                              label: "Yes",
                            },
                            {
                              id: 2,
                              label: "No",
                            },
                          ]}
                          // options={formOptions.isSeeingRE}
                          hasError={Boolean(errors.isSeeingReId)}
                        />
                      </Grid>
                      <HiddenLogicComponent
                        value={values.isSeeingRe}
                        clearFields={clearFields}
                        names={["journeyUpdateEventId", "notAtReReasonIds"]}
                      >
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Where are you on the fertility treatment journey?"
                            value={values.journeyUpdateEventId}
                            onChange={(value) => {
                              setField("journeyUpdateEventId", value);
                            }}
                            options={formOptions.journeyUpdateEvent}
                            hasError={Boolean(errors.journeyUpdateEventId)}
                          />
                        </Grid>
                        <HiddenLogicComponent
                          value={
                            values.journeyUpdateEventId ===
                            getIdFromLabel({
                              label: "Other",
                              options: formOptions.journeyUpdateEvent,
                            })
                          }
                          clearFields={clearFields}
                          names={["otherJourneyUpdateEvent"]}
                        >
                          <Grid item xs={12}>
                            <TextInput
                              multiline={true}
                              minRows={2}
                              maxRows={4}
                              variant="outlined"
                              label="Please tell us where you are on the fertility treatment journey"
                              name="otherJourneyUpdateEvent"
                              value={values.otherJourneyUpdateEvent}
                              error={Boolean(errors.otherJourneyUpdateEvent)}
                              onChange={(e) => {
                                setField(
                                  "otherJourneyUpdateEvent",
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>
                        </HiddenLogicComponent>
                      </HiddenLogicComponent>
                      <HiddenLogicComponent
                        value={values.isSeeingRe === false}
                        clearFields={clearFields}
                        names={["journeyUpdateEventId", "notAtReReasonIds"]}
                      >
                        <Grid item xs={12}>
                          <MultiSelect
                            heading="Why are you not seeing a fertility specialist?"
                            onChange={(values) => {
                              setField("notAtReReasonIds", values);
                            }}
                            values={values.notAtReReasonIds}
                            options={formOptions.notAtReReason}
                            hasError={Boolean(errors.notAtReReasonIds)}
                          />
                        </Grid>
                        <HiddenLogicComponent
                          value={values.notAtReReasonIds.includes(
                            getIdFromLabel({
                              label: "Other",
                              options: formOptions.notAtReReason,
                            })
                          )}
                          clearFields={clearFields}
                          names={["otherNotAtReReason"]}
                        >
                          <Grid item xs={12}>
                            <TextInput
                              multiline={true}
                              minRows={2}
                              maxRows={4}
                              label="Please tell us why you are not seeing a fertility specialist"
                              name="otherNotAtReReason"
                              value={values.otherNotAtReReason}
                              onChange={(e) => {
                                setField("otherNotAtReReason", e.target.value);
                              }}
                              error={Boolean(errors.otherNotAtReReason)}
                              variant="outlined"
                            />
                          </Grid>
                        </HiddenLogicComponent>
                      </HiddenLogicComponent>
                      {!isValid && (
                        <Grid item xs={12}>
                          <ErrorBanner />
                        </Grid>
                      )}
                      <Grid container justifyContent="center" item xs={12}>
                        <SubmitButton disabled={!isValid} />{" "}
                      </Grid>
                      <Grid item xs={12}>
                        <RobynDisclaimer
                          hasHeading
                          veevaCode="US-RMMH-2200350"
                        />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};

export default FollowUpForm;
