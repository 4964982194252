import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Typography, Stack, TextField, Box } from "@mui/material";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
import CrmChip from "src/components/common/Crm/Chip";
import TagPopper from "./TagPopper";
import ToolTip from "src/components/common/ToolTip";

/**
 * A dropdown component that allows multiple selections.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The id of the dropdown.
 * @param {string} props.label - The label of the dropdown.
 * @param {Array<Object>} props.options - The options to display in the dropdown.
 * @param {Array<number>} props.values - The selected values.
 * @param {Function} props.setValues - The function to set the selected values and whether an option was previously selected (to be removed).
 * @param {Function} props.addValue - The function to add a selected value, cannot be used in conjunction with setValues.
 * @param {Function} props.removeValue - The function to remove a selected value, cannot be used in conjunction with setValues.
 * @returns {JSX.Element} The rendered MultiSelect component.
 */

const MultiSelect = ({
  id,
  label,
  options,
  values: initialValues,
  setValues,
  addValue,
  removeValue,
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTagPopperOpen, setIsTagPopperOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const anchorRef = useRef(null);
  const { isDesktop } = useDeviceBreakpoint();

  const handleChange = (values, reason, selectedOption, id) => {
    const newValues = values;

    try {
      // Check if setValues, addValue, and removeValue are defined
      if (setValues && (addValue || removeValue)) {
        throw new Error(
          "if setValues is defined, addValue and removeValue cannot be defined or V.V."
        );
      }
      // Check if setValues, addValue, and removeValue are not defined
      if (!setValues && (!addValue || !removeValue)) {
        throw new Error(
          "setValues, or addValue and removeValue need to be defined"
        );
      }

      // If setValues is defined pass up the new array of values:
      if (setValues) {
        setValues(newValues, id);
      } else {
        // Check the reason for the change and add or remove the value accordingly
        switch (reason) {
          case "selectOption":
            addValue(selectedOption.id);
            break;
          case "removeOption":
            removeValue(selectedOption.id);
            break;
          default:
            break;
        }
        // Set the new values
        setSelectedValues(newValues);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (initialValues) {
      setSelectedValues(initialValues);
    }
  }, [initialValues]);

  // Close the dropdown if the tag popper is open
  useEffect(() => {
    if (isTagPopperOpen) {
      setIsDropdownOpen(false);
    }
  }, [isTagPopperOpen]);

  // Close the tag popper if the dropdown is open
  useEffect(() => {
    if (isDropdownOpen) {
      setIsTagPopperOpen(false);
    }
  }, [isDropdownOpen]);

  return (
    <div id={id} ref={anchorRef} style={{ width: "100%" }}>
      <Autocomplete
        label={label}
        multiple
        sx={{
          position: "relative",
          wrap: "nowrap",
          "& .MuiAutocomplete-root.MuiAutocomplete-inputRoot": {
            wrap: "nowrap",
          },
          "& label": {
            zIndex: 0,
          },
        }}
        limitTags={isDesktop ? 1 : 2}
        open={isDropdownOpen}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        value={selectedValues}
        disableClearable
        onChange={(e, values, reason, details) => {
          const selectedOption = details?.option;
          handleChange(values, reason, selectedOption, id);
        }}
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          option?.id === value?.id || option?.id === null
        }
        renderTags={(tagValues, getTagProps, ownerState) => {
          const displayedTagValues = tagValues.slice(0, ownerState.limitTags);
          const moreTagValues = tagValues.slice(ownerState.limitTags);

          return (
            <>
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  wrap: "nowrap",
                }}
              >
                {displayedTagValues.map((option, index) => {
                  return (
                    <CrmChip
                      key={option.id}
                      id={option.id}
                      selectLabel={label}
                      label={option.label}
                      color={option?.hexCode}
                      {...getTagProps({ index })}
                    />
                  );
                })}
              </Stack>
              {Boolean(moreTagValues.length > 0) && (
                <Box
                  sx={{
                    position: "absolute",
                    right: (theme) => theme.spacing(3.5),
                    top: (theme) => theme.spacing(1.5),
                  }}
                >
                  <TagPopper
                    anchorEl={anchorRef.current}
                    tags={moreTagValues}
                    handleRemoveTag={(tagToBeRemoved) => {
                      const newTags = selectedValues.filter(
                        (selectedValue) =>
                          selectedValue.id !== tagToBeRemoved.id
                      );

                      handleChange(newTags, "removeOption", tagToBeRemoved, id);
                    }}
                    isTagPopperOpen={isTagPopperOpen}
                    setIsTagPopperOpen={setIsTagPopperOpen}
                  />
                </Box>
              )}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            sx={{
              "& fieldset": {
                border: props?.variant && "none",
              },
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <li {...props}>
            <ToolTip title={option?.label || ""} placement="top">
              <Typography noWrap>{option.label}</Typography>
            </ToolTip>
          </li>
        )}
        {...props}
      />
    </div>
  );
};

export default MultiSelect;

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.arrayOf(PropTypes.object || PropTypes.number),
  setValues: PropTypes.func,
  addValue: PropTypes.func,
  removeValue: PropTypes.func,
};
