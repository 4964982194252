import { Typography, Divider, Stack } from "@mui/material";
import Loader from "src/components/common/Loader";
import PropTypes from "prop-types";

const BoxContainer = ({
  children,
  isLoading,
  hasError,
  errorMessage,
  opacity,
  hasChildren,
}) => {
  return (
    <Stack style={{ opacity }}>
      {/* LOADING STATE */}
      {isLoading && (
        <>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Loader />
          </Stack>
          <Divider />
        </>
      )}

      {/* ERROR STATE */}
      {hasError && !isLoading && (
        <>
          <Stack sx={{ justifyContent: "center", width: "100%" }}>
            <Typography color="error" align="center" variant="body2">
              {errorMessage}
            </Typography>
          </Stack>
          <Divider />
        </>
      )}

      {!hasChildren && !isLoading && (
        <Typography variant="body1" align="center">
          No items found
        </Typography>
      )}

      {children}
    </Stack>
  );
};

export default BoxContainer;

BoxContainer.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
};
