import PropTypes from "prop-types";
import { useContext } from "react";
import { OptionContext } from "src/context/OptionContext";
import * as Yup from "yup";
import { Formik } from "formik";
import { Stack, Typography, Button } from "@mui/material";
import DatePicker from "src/components/common/DatePicker";
import TextInput from "src/components/Forms/common/TextInput";
import HiddenLogicComponent from "src/components/Forms/common/HiddenLogicComponent";
import MultiSelectDropdown from "src/components/common/MultiSelect";
import SingleSelect from "src/components/common/SingleSelect";

// TODO: Update shape to make certain comment or journey event is not empty
const validationSchema = Yup.object().shape({
  createdAt: Yup.date().required("Required"),
  comment: Yup.string()
    .nullable()
    .when("journeyUpdateEvent", {
      is: (event) => !event || event === "",
      then: Yup.string().required("Required"),
    }),
  journeyUpdateEvent: Yup.object().nullable(),
  otherJourneyUpdateEvent: Yup.string().nullable(),
  notAtReReasons: Yup.array().nullable(),
  otherNotAtReReason: Yup.string().nullable(),
});

const initialValues = {
  createdAt: new Date(),
  comment: "",
  journeyUpdateEvent: null,
  otherJourneyUpdateEvent: "",
  notAtReReasons: [],
  otherNotAtReReason: null,
};

const ClientJourneyForm = ({
  onSubmit,
  onCancel,
  initialValues: previousValues,
  buttonText,
  clientId,
}) => {
  const { journeyUpdateEventOptions, notAtReReasonOptions } =
    useContext(OptionContext);

  const handleSubmit = (
    { journeyUpdateEvent, notAtReReasons, comment: commentBody, ...payload },
    resetForm
  ) => {
    try {
      const journeyUpdateEventId = journeyUpdateEvent?.id || null;
      const notAtReReasonIds = Boolean(notAtReReasons?.length)
        ? notAtReReasons.map((reason) => reason.id)
        : [];
      const comment = commentBody || null;

      const createdAt = new Date();

      if (payload.createdAt) {
        // Set the day number to the day of payload.createdAt
        createdAt.setDate(new Date(payload.createdAt).getDate());
        // Set the month number to the month of payload.createdAt
        createdAt.setMonth(new Date(payload.createdAt).getMonth());
        // Set the year number to the year of payload.createdAt
        createdAt.setFullYear(new Date(payload.createdAt).getFullYear());
      }

      onSubmit(
        {
          ...payload,
          journeyUpdateEventId,
          notAtReReasonIds,
          comment,
          createdAt,
        },
        resetForm
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur={false}
      initialValues={previousValues || initialValues}
      resetForm={{ values: initialValues }}
      onSubmit={(payload, { resetForm }) => handleSubmit(payload, resetForm)}
    >
      {({
        values,
        errors,
        setFieldError,
        handleSubmit,
        setFieldValue,
        handleChange,
        isValid,
      }) => {
        return (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Stack spacing={2}>
              <DatePicker
                variant="outlined"
                value={values.createdAt}
                fullWidth
                handleChange={(value) => {
                  setFieldValue("createdAt", value);
                  setFieldError("createdAt", undefined);
                }}
                hasError={Boolean(errors?.createdAt)}
                placeholder="Event Date"
              />

              <TextInput
                name="comment"
                variant="outlined"
                multiline
                minRows={2}
                maxRows={4}
                value={values?.comment || ""}
                onChange={(e) => {
                  setFieldValue("comment", e.target.value);
                  setFieldError("comment", undefined);
                }}
                placeholder="Write a comment..."
                error={Boolean(errors?.comment)}
              />
              <SingleSelect
                id="journeyUpdateEvent"
                label="Add Journey Event"
                value={values.journeyUpdateEvent?.id || ""}
                options={journeyUpdateEventOptions}
                name="journey-update-event"
                onChange={(valueId) => {
                  const value = journeyUpdateEventOptions.find(
                    (option) => option.id === valueId
                  );
                  setFieldValue("journeyUpdateEvent", value);
                  setFieldError("journeyUpdateEvent", undefined);
                }}
                hasError={Boolean(errors?.journeyUpdateEvent)}
              />

              <HiddenLogicComponent
                value={values.journeyUpdateEvent?.label === "Other"}
                names={["otherJourneyUpdateEvent"]}
                clearFields={() => {
                  setFieldValue("otherJourneyUpdateEvent", "");
                  setFieldError("otherJourneyUpdateEvent", undefined);
                }}
              >
                <TextInput
                  multiline={true}
                  placeholder={"Please specify other Event"}
                  name="otherJourneyUpdateEvent"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.otherJourneyUpdateEvent}
                />
              </HiddenLogicComponent>

              <HiddenLogicComponent
                value={["Stopped Seeing RE", "Not at RE"].some(
                  (value) => values.journeyUpdateEvent?.label === value
                )}
                names={["notAtReReasons"]}
                clearFields={() => {
                  setFieldValue("notAtReReasons", []);
                  setFieldError("notAtReReasons", undefined);
                }}
              >
                <MultiSelectDropdown
                  id="notAtReReason"
                  variant="outlined"
                  values={values.notAtReReasons}
                  options={notAtReReasonOptions}
                  setValues={(values) => {
                    setFieldValue("notAtReReasons", values);
                    setFieldError("notAtReReasons", undefined);
                  }}
                  label={"Not At RE Reasons"}
                  name={"Not at RE Reason"}
                />
                <HiddenLogicComponent
                  value={values.notAtReReasons?.some((arr) =>
                    ["Other"].some((value) => arr.label === value)
                  )}
                  names={["otherNotAtReReason"]}
                  clearFields={() => {
                    setFieldValue("otherNotAtReReason", "");
                    setFieldError("otherNotAtReReason", undefined);
                  }}
                >
                  <Stack>
                    <Typography variant="overline">
                      Other No RE Reason
                    </Typography>
                    <TextInput
                      multiline={true}
                      placeholder={
                        "Please specify other reason for not seeing RE"
                      }
                      name="otherNotAtReReason"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.otherNotAtReReason}
                    />
                  </Stack>
                </HiddenLogicComponent>
              </HiddenLogicComponent>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                {onCancel != null && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                >
                  {buttonText}
                </Button>
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

ClientJourneyForm.propTypes = {
  initialValues: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  clientId: PropTypes.string.isRequired,
};

export default ClientJourneyForm;
