import propTypes from "prop-types";
import { getContrastRatio } from "@mui/material/styles";

/**
 *
 * @param {string} backgroundColor  - The background color
 * @returns {string} either white or black depending on the contrast ratio between the background color and the two colors.
 * @throws  If an invalid color is provided, an error is thrown.
 * @example
 * colorContrastChecker("#FFFFFF"); // #000000 (black)
 * colorContrastChecker("#000000") // #FFFFFF (white)
 * colorContrastChecker("#FF0000") // #000000 (black)
 */
const getFontColorFromBackground = (backgroundColor) => {
  try {
    const bgColor = backgroundColor?.trim().toLowerCase();

    if (!bgColor) {
      throw new Error("No color provided");
    }

    if (typeof bgColor !== "string") {
      throw new Error(`Invalid color type ${typeof bgColor}`);
    }

    if (!/^#[0-9a-f]{6}$/i.test(bgColor)) {
      throw new Error(`Invalid color code ${bgColor}`);
    }

    const contrastThreshold = 10;
    const muiContrast = getContrastRatio(bgColor, "#000000");

    if (muiContrast > contrastThreshold) {
      return "#000000";
    } else {
      return "#FFFFFF";
    }
  } catch (error) {
    console.error(error.message);
    return "#000000";
  }
};

export default getFontColorFromBackground;

getFontColorFromBackground.propTypes = {
  backgroundColor: propTypes.string,
};
