import { FormControlLabel, Radio, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  error: {
    border: `1px solid ${theme.palette.error.muiError}`,
  },
  icon: {
    borderRadius: "50%",
    width: 27,
    height: 27,
    backgroundColor: theme.palette.background.default,
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 27,
      height: 27,
      borderRadius: "50%",
      backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 50%,transparent 55%)`,
      content: '""',
    },
  },
}));

const RadioSelect = ({ label, value, hasError }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      sx={{
        my: 0.7,
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
      label={
        <Typography
          sx={{
            fontFamily: "Brandon",
            fontSize: 18,
            display: "flex",
            alignItems: "center",
          }}
        >
          {label}
        </Typography>
      }
      control={
        <Radio
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={
            <span className={clsx(hasError && classes.error, classes.icon)} />
          }
        />
      }
    />
  );
};
export default RadioSelect;
