import PropTypes from "prop-types";

import ClientJourneyForm from "./Form";
import ClientJourneyItem from "./Item";

const Update = ({
  item,
  itemType,
  onEdit,
  index,
  editId,
  onCancel,
  clientId,
}) => {
  return (
    <>
      {editId === index + 1 ? (
        <ClientJourneyForm
          onSubmit={(newValues) => {
            onEdit(newValues);
          }}
          onCancel={onCancel}
          initialValues={{
            itemId: item.id,
            itemType: itemType,
            createdAt: item.createdAt,
            comment: item.comment,
            journeyUpdateEvent: item.journeyUpdateEvent,
            notAtReReasons: item.notAtReReasons,
            otherNotAtReReason: item.otherNotAtReReason,
          }}
          buttonText="Update"
          clientId={clientId}
        />
      ) : (
        <ClientJourneyItem item={item} />
      )}
    </>
  );
};

export default Update;

Update.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string,
  onEdit: PropTypes.func,
  index: PropTypes.number,
  editId: PropTypes.number,
  onCancel: PropTypes.func,
  clientId: PropTypes.string,
};
