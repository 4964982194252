import React, { useRef, useState, useContext } from "react";
import axios from "src/utils/axios";
import {
  Container,
  Button,
  CircularProgress,
  SvgIcon,
  Stack,
} from "@mui/material";
import { Download as DownloadIcon } from "react-feather";
import Page from "src/components/common/Page";
import ClientTable from "src/components/Clients/Table";
import { UserContext } from "src/context/UserContext";
import { ClientsContextProvider } from "src/context/ClientsContext";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

const getCsvExport = async (apiSlug) => {
  try {
    const { data } = await axios.get(`/api/clients${apiSlug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

const Clients = () => {
  const pageRef = useRef(null);
  const { isAgentAdmin } = useContext(UserContext);
  const [typeLoading, setTypeLoading] = useState("");
  const [csvExport, setCsvExport] = useState([]);
  const clientCsvLink = useRef();
  const surveyCsvLink = useRef();
  const eventsCsvLink = useRef();

  const handleCsvExport = async ({ type, apiSlug, linkRef }) => {
    try {
      setTypeLoading(type);

      const csvExport = await getCsvExport(apiSlug);
      setCsvExport(csvExport);
      linkRef.current.link.click();
    } catch (error) {
      console.log(error.message);
      toast.error(`Downloading ${type}`);
    } finally {
      setTypeLoading("");
    }
  };

  const buttons = [
    {
      text: "Clients CSV",
      type: "clients",
      linkRef: clientCsvLink,
      apiSlug: "/export",
    },
    {
      text: "Surveys CSV",
      type: "surveys",
      linkRef: surveyCsvLink,
      apiSlug: "/export/surveys",
    },
    {
      text: "Events CSV",
      type: "events",
      linkRef: eventsCsvLink,
      apiSlug: "/export/events",
    },
    {
      text: "Journey Updates CSV",
      type: "journey-updates",
      linkRef: eventsCsvLink,
      apiSlug: "/export/journeys",
    },
  ];

  return (
    <ClientsContextProvider>
      <Page
        sx={{
          backgroundColor: "background.dark",
          minHeight: "100%",
        }}
        title="Clients"
        ref={pageRef}
      >
        <Container
          sx={{
            p: {
              xs: 0,
              md: 2,
            },
          }}
          maxWidth="xl"
        >
          <Stack useFlexGap spacing={2} sx={{ my: { xs: 2, md: 0 } }}>
            {isAgentAdmin && (
              <Stack
                direction={"row"}
                useFlexGap
                spacing={1}
                sx={{
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  mr: { xs: 2, md: 0 },
                }}
              >
                {buttons.map(({ type, text, linkRef, apiSlug }) => (
                  <React.Fragment key={type}>
                    <Button
                      startIcon={
                        <>
                          {typeLoading === type ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={20}
                            />
                          ) : (
                            <SvgIcon fontSize="small">
                              <DownloadIcon />
                            </SvgIcon>
                          )}
                        </>
                      }
                      onClick={() =>
                        handleCsvExport({ type, apiSlug, linkRef })
                      }
                      color="primary"
                      variant="contained"
                    >
                      {text}
                    </Button>
                    <CSVLink
                      data={csvExport}
                      filename={`${type}.csv`}
                      ref={linkRef}
                      target="_blank"
                    />
                  </React.Fragment>
                ))}
              </Stack>
            )}
            <ClientTable title="Clients" pageRef={pageRef} />
          </Stack>
        </Container>
      </Page>
    </ClientsContextProvider>
  );
};

export default Clients;
