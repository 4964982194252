import axios from "src/utils/axios";
import { createContext, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { UserContext } from "src/context/UserContext";

const initialOption = {
  ageRangeOptions: [],
  activePhysicianOptions: [],
  currentTreatmentOptions: [],
  threadFlagOptions: [],
  agentOptions: [],
  journeyStageOptions: [],
  journeyUpdateEventOptions: [],
  tagOptions: [],
  topicOptions: [],
  subtopicOptions: [],
  notAtReReasonOptions: [],
  noReReasonOptions: [],
  stoppedReReasonOptions: [],
};

const optionReducer = (state, action) => {
  switch (action.type) {
    case "SET_AGE_RANGE_OPTIONS":
      return {
        ...state,
        ageRangeOptions: action.payload,
      };

    case "SET_ACTIVE_PHYSICIAN_OPTIONS":
      return {
        ...state,
        activePhysicianOptions: action.payload,
      };

    case "SET_CURRENT_TREATMENT_OPTIONS":
      return {
        ...state,
        currentTreatmentOptions: action.payload,
      };

    case "SET_AGENT_OPTIONS":
      return {
        ...state,
        agentOptions: action.payload,
      };

    case "SET_THREAD_FLAG_OPTIONS":
      return {
        ...state,
        threadFlagOptions: action.payload,
      };

    case "SET_JOURNEY_STAGE_OPTIONS":
      return {
        ...state,
        journeyStageOptions: action.payload,
      };

    case "SET_JOURNEY_EVENT_OPTIONS":
      return {
        ...state,
        journeyUpdateEventOptions: action.payload,
      };

    case "SET_TAG_OPTIONS":
      return {
        ...state,
        tagOptions: action.payload,
      };
    case "SET_TOPIC_OPTIONS":
      return {
        ...state,
        topicOptions: action.payload,
      };
    case "SET_SUBTOPIC_OPTIONS":
      return {
        ...state,
        subtopicOptions: action.payload,
      };
    case "SET_NOT_AT_RE_REASON_OPTIONS":
      return {
        ...state,
        notAtReReasonOptions: action.payload,
      };
    case "SET_NO_RE_REASON_OPTIONS":
      return {
        ...state,
        noReReasonOptions: action.payload,
      };
    case "SET_STOPPED_RE_REASON_OPTIONS":
      return {
        ...state,
        stoppedReReasonOptions: action.payload,
      };
    default:
      return state;
  }
};

export const OptionContext = createContext();

const OptionContextProvider = (props) => {
  const userState = useContext(UserContext);
  const [options, optionDispatch] = useReducer(optionReducer, initialOption);

  const setTopicOptions = (topicId) => {
    optionDispatch({
      type: "SET_TOPIC_OPTIONS",
      topicId,
    });
  };

  const setSubtopicOptions = (subtopicId) => {
    optionDispatch({
      type: "SET_SUBTOPIC_OPTIONS",
      subtopicId,
    });
  };

  // GET PUBLIC OPTIONS:
  useEffect(() => {
    (async () => {
      try {
        const publicSlugs = [
          "age-range", // 0
          "active-physician", // 1
          "current-treatment", // 2
          "thread-flag", // 3
          "journey-stage", // 4
          "journey-update-event", // 5
          "tag", // 6
          "topic", // 7
          "subtopic", // 8
          "not-at-re-reason", // 9
          "no-re-reason", // 10
          "stopped-re-reason", // 11
        ];

        const promises = publicSlugs.map((slug) =>
          axios.get(`/api/crm/options/${slug}`)
        );

        const optionsData = (await Promise.all(promises)).map(
          (response) => response.data
        );

        optionDispatch({
          type: "SET_AGE_RANGE_OPTIONS",
          payload: [{ id: null, label: "-" }, ...optionsData[0]],
        });
        optionDispatch({
          type: "SET_ACTIVE_PHYSICIAN_OPTIONS",
          payload: [...optionsData[1]],
        });
        optionDispatch({
          type: "SET_CURRENT_TREATMENT_OPTIONS",
          payload: [...optionsData[2]],
        });
        optionDispatch({
          type: "SET_THREAD_FLAG_OPTIONS",
          payload: [...optionsData[3]],
        });
        optionDispatch({
          type: "SET_JOURNEY_STAGE_OPTIONS",
          payload: [{ id: null, label: "-" }, ...optionsData[4]],
        });
        optionDispatch({
          type: "SET_JOURNEY_EVENT_OPTIONS",
          payload: [{ id: null, label: "-" }, ...optionsData[5]],
        });
        optionDispatch({
          type: "SET_TAG_OPTIONS",
          payload: [...optionsData[6]],
        });
        optionDispatch({
          type: "SET_TOPIC_OPTIONS",
          payload: [...optionsData[7]],
        });
        optionDispatch({
          type: "SET_SUBTOPIC_OPTIONS",
          payload: [...optionsData[8]],
        });
        optionDispatch({
          type: "SET_NOT_AT_RE_REASON_OPTIONS",
          payload: [...optionsData[9]],
        });
        optionDispatch({
          type: "SET_NO_RE_REASON_OPTIONS",
          payload: [...optionsData[10]],
        });
        optionDispatch({
          type: "SET_STOPPED_RE_REASON_OPTIONS",
          payload: [...optionsData[11]],
        });
      } catch (error) {
        toast.error(`Error getting options`);
        console.error("error", error.message);
      }
    })();
  }, []);

  // GET PRIVATE OPTIONS:
  useEffect(() => {
    if (!userState.agent) return;
    (async () => {
      try {
        const { data: agents } = await axios.get(`/api/agents`, {
          params: {
            role: "Coach",
          },
        });

        if (agents?.length === 0) {
          throw new Error("No agents found");
        }

        optionDispatch({
          type: "SET_AGENT_OPTIONS",
          payload: [{ id: null, name: "-" }, ...agents],
        });
      } catch (error) {
        toast.error(`Error getting agent options`);
        console.error("error", error.message);
      }
    })();
  }, [userState.agent]);

  const value = {
    ...options,
    setTopicOptions,
    setSubtopicOptions,
  };

  return (
    <OptionContext.Provider value={value}>
      {props.children}
    </OptionContext.Provider>
  );
};

const OptionContextConsumer = OptionContext.Consumer;

export { OptionContextProvider, OptionContextConsumer };
