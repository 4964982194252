import { Chip as MuiChip } from "@mui/material";
import Tooltip from "src/components/common/ToolTip";
import getFontColorFromBackground from "src/utils/getFontColorFromBackground";

const Chip = ({ label, selectLabel, color: bgColor, className, ...props }) => {
  return (
    <Tooltip arrow title={label} placement="top-start">
      <MuiChip
        size="small"
        sx={{
          color: (theme) =>
            getFontColorFromBackground(bgColor || theme.palette.babyBlue),
          backgroundColor: bgColor || "babyBlue",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: 400,
          maxWidth: 100,
          "& .MuiChip-deleteIconSmall": {
            opacity: 0.4,
            color: "black",
          },
        }}
        label={label}
        {...props}
      />
    </Tooltip>
  );
};

export default Chip;
