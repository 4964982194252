import * as Yup from "yup";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import zipCodeValidator from "src/utils/zipCodeValidator";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]{2,}$/u,
      "Invalid first name. Cannot include special characters or numbers."
    )
    .required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test(
      "validPhone",
      "Invalid Phone Number. Please enter a valid phone number",
      function (phone) {
        if (!phone) {
          return false;
        }

        const isUs = parsePhoneNumber(phone).country === "US";

        if (!isUs) {
          return false;
        }
        return isValidPhoneNumber(phone);
      }
    ),
  email: Yup.string()
    .email("Invalid Email. Please enter a valid email address")
    .required("Required"),
  zipCode: Yup.string()
    .required("Required")
    .min(5, "Must be 5 digits")
    .max(5, "Must be 5 digits")
    .matches(/^[0-9]+$/, "Must be numeric")
    .test(
      "validZip",
      "Invalid zip code. Please enter a valid zip code",
      function (zipCode) {
        if (!zipCode) {
          return false;
        }

        const isUSZipCode = zipCodeValidator(zipCode);

        return isUSZipCode;
      }
    ),
  // .required("Required"),
  journeyStageId: Yup.number().required("Required").typeError("Required"),
  // Trying to Conceive Duration:
  ttcDurationId: Yup.number().required("Required").typeError("Required"),
  // Active Physicians:
  activePhysicianIds: Yup.array()
    .min(1)
    .required("Required")
    .typeError("Required"),
  // Event Journey:
  journeyUpdateEventId: Yup.number().when("activePhysicianIds", {
    is: (activePhysicianIds) => {
      const check = activePhysicianIds?.some((id) =>
        [3].some((value) => id === value)
      );

      if (check) {
        return true;
      }
    },
    then: Yup.number().required("Required").typeError("Required"),
    otherwise: Yup.number().nullable().optional(),
  }),
  otherJourneyUpdateEvent: Yup.string().when("journeyUpdateEventId", {
    is: (journeyUpdateEventId) => {
      return journeyUpdateEventId === 31;
    },
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),

  //  IS NOT SEEING RE:
  notAtReReasonIds: Yup.array().when("isSeeingRe", {
    is: (isSeeingRe) => {
      return !isSeeingRe;
    },
    then: Yup.array().test("notAtReReasonIds", "Required", function (values) {
      return values.length !== 0;
    }),
    otherwise: Yup.array(),
  }),
  otherNotAtReReason: Yup.string().when("notAtReReasonIds", {
    is: (notAtReReasonIds) => {
      const check = notAtReReasonIds?.some((id) =>
        [5].some((value) => id === value)
      );

      if (check) {
        return true;
      }
    },
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),
  // Demographics:
  ageRangeId: Yup.number().required("Required").typeError("Required"),
  ethnicIdentityIds: Yup.array()
    .min(1)
    .required("Required")
    .typeError("Required"),
  genderIdentityId: Yup.number().required("Required").typeError("Required"),

  referralId: Yup.number().required("Required").typeError("Required"),
  otherReferral: Yup.string().when("source", {
    is: (referralId) => {
      return referralId === 9;
    },
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),
  // Optin:
  optinId: Yup.number().required("Required").typeError("Required"),
  // Accept Terms and Conditions:
  tAndCId: Yup.number()
    .required("Required")
    .typeError("Required")
    .test("isAccepted", "Must accept", (val) => val === 1),
  recaptcha: Yup.string().required("Required"),
});

export default validationSchema;
